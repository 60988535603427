import { AsyncPipe } from '@angular/common';
import { Component, computed, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { ActivatedRoute } from '@angular/router';
import { CheckBlocksTileComponent } from '../../../check-block/components/tile/check-blocks-tile.component';
import { PermissionsService } from '../../../permissions/permissions.service';
import { SamplesService } from '../../service/samples.service';

@Component({
  standalone: true,
  imports: [AsyncPipe, CheckBlocksTileComponent],
  providers: [SamplesService],
  selector: 'app-data-entry-check-blocks',
  template: `
    <app-check-blocks-tile
      tag="sample-checkblocks"
      [checkBlocks]="checkBlocks() | async"
      [title]="title | async"
      [canShowResolved]="true"
    >
      <i whenNone>No check blocks to display.</i>
    </app-check-blocks-tile>
  `,
})
export class DataEntryCheckBlocksComponent {
  private readonly sampleService = inject(SamplesService);
  private readonly routeParams = toSignal(inject(ActivatedRoute).params);

  readonly checkBlocks = computed(() =>
    this.sampleService.getCheckBlocks(this.routeParams().sampleID),
  );

  readonly title = inject(PermissionsService)
    .hasPermission('checkblocks', 'create')
    .then((isSenior) => (isSenior ? 'Checkblocks' : 'Your active checkblocks'));
}
