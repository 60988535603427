import { HttpClient } from '@angular/common/http';
import { Component, inject, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormControlStatus, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { environment } from 'apps/mba-cpr-survey-portal/src/environments/environment';
import { combineLatest, lastValueFrom, Subject, takeUntil } from 'rxjs';

@Component({
  standalone: true,
  imports: [
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatTooltipModule,
    ReactiveFormsModule,
  ],
  selector: 'app-tow-id-form-control',
  template: `
    <div style="display: flex; gap: var(--global-padding)">
      <mat-form-field style="width: 100%">
        <mat-label>Route</mat-label>
        <input matInput maxlength="4" pattern="[-a-zA-Z]{2,4}" required [formControl]="routeCode" />
      </mat-form-field>

      <mat-form-field style="width: 100%">
        <mat-label>Tow Number</mat-label>
        <input
          matInput
          type="number"
          min="1"
          max="9999"
          step="0"
          required
          [formControl]="towNumber"
        />
        @if (towNumber.enabled) {
          <button
            mat-icon-button
            matIconSuffix
            (click)="$event.stopPropagation(); autoIncrement()"
            matTooltip="Auto-increment"
            [disabled]="routeCode.invalid"
          >
            <mat-icon>bolt</mat-icon>
          </button>
        }
        @if (towNumber.enabled) {
          <mat-hint
            >Click on the button to the right to autofill this field with the next incremental
            number for the route.</mat-hint
          >
        }
      </mat-form-field>
    </div>
  `,
})
export class TowIdFormControlComponent implements OnInit, OnDestroy {
  private readonly unsubscribe$ = new Subject<void>();

  readonly routeCode = new FormControl<string>('');
  readonly towNumber = new FormControl<number>(null);

  // The parent-provided form control to observe and update
  @Input() towId: FormControl<string>;

  private readonly http = inject(HttpClient);

  ngOnInit() {
    // Reactively combine on change to create the full towId value
    combineLatest([this.routeCode.valueChanges, this.towNumber.valueChanges])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([routeCode, towNumber]) => {
        this.towId.setValue((towNumber ?? '') + routeCode, { emitEvent: false });
        this.towId.markAsDirty();
        this.towId.markAsTouched();
      });

    // Copy initial status and bind future status changes
    const initStatus = (status: FormControlStatus) => {
      if (status === 'DISABLED') {
        this.routeCode.disable({ emitEvent: false });
        this.towNumber.disable({ emitEvent: false });
      } else {
        this.routeCode.enable({ emitEvent: false });
        this.towNumber.enable({ emitEvent: false });
      }
    };
    initStatus(this.towId.status);
    this.towId.statusChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(initStatus);

    // Copy initial value and bind future value changes
    const initValues = (value: string) => {
      const routeCodeResult = /[a-z-]+$/i.exec(value);
      if (routeCodeResult?.length) {
        this.routeCode.setValue(routeCodeResult[0]);
      }

      const towNumberResult = /^[0-9]+/i.exec(value);
      if (towNumberResult?.length) {
        this.towNumber.setValue(parseInt(towNumberResult[0]));
      }
    };
    initValues(this.towId.value);
    this.towId.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(initValues);
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  async autoIncrement() {
    this.towNumber.setValue(
      await lastValueFrom(
        this.http.get<number>(
          `${environment.cprServiceApi}recording-event/${this.routeCode.value}/next`,
        ),
      ),
    );
  }
}
