import { Component, computed, inject, OnDestroy } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ITableDataSource } from '@suvo-bi-lib';
import { FinaliseTowDialogComponent } from '../../../finalisation/components/finalise-tow-dialog/finalise-tow-dialog.component';
import { ISample } from '../../../samples/interface/sample.interface';
import { SamplesService } from '../../../samples/service/samples.service';
import { ITowRouteData } from '../../interface/tow-route-data.interface';
import { TowsService } from '../../service/tows.service';

@Component({
  template: `
    @if (tow(); as tow) {
      <app-header>
        @if (!towLogEntered() && !samples?.count) {
          <button mat-flat-button (click)="onDelete()">Delete</button>
        }

        @if (tow.metadata?.status !== 'finalised') {
          <button
            mat-flat-button
            (click)="finaliseTow()"
            [checkPermission]="['recordingEvent', 'finalise']"
          >
            Finalise Tow
          </button>
        }

        <a mat-flat-button routerLink="print">Print</a>
      </app-header>

      <div style="display: grid; margin: 0 24px; gap: 24px">
        <app-tow-info-tile [tow]="tow" />

        <div class="mat-mdc-card" style="display: block; overflow: hidden">
          <app-tow-log-map-tile [tow]="tow">
            <a mat-raised-button routerLink="log">View Tow Log</a>
          </app-tow-log-map-tile>
        </div>

        <app-tow-log-tile
          style="grid-column: span 2"
          mode="simple"
          [tow]="tow"
          [towLog]="towLog()"
        />

        <app-tow-samples-tile style="grid-column: span 2" mode="simple" [tow]="tow" />
      </div>
    }
  `,
})
export class TowOverviewScreenComponent implements OnDestroy {
  private readonly dialog = inject(MatDialog);
  private readonly router = inject(Router);
  private readonly sampleService = inject(SamplesService);
  private readonly towService = inject(TowsService);

  private readonly routeData = toSignal<ITowRouteData>(inject(ActivatedRoute).data);
  readonly tow = computed(() => this.routeData().data);
  readonly towLog = computed(() => this.routeData().towLog());

  samples: ITableDataSource<ISample>;

  readonly towLogEntered = computed(() => {
    const towLog = this.towLog();
    return towLog && towLog.length > 0;
  });

  private readonly onRouteChange = inject(ActivatedRoute).data.subscribe(
    ({ data: tow }: ITowRouteData) => {
      this.sampleService
        .getPaginated('', { simpleFilters: { towId: `ObjectId:${tow._id}` } })
        .then((samples) => (this.samples = samples));
    },
  );

  ngOnDestroy() {
    this.onRouteChange.unsubscribe();
  }

  onDelete() {
    this.towService.delete(this.tow()._id, () => this.router.navigate(['/tows']));
  }

  finaliseTow() {
    const tow = this.tow();
    this.dialog.open(FinaliseTowDialogComponent, {
      data: {
        towId: tow._id,
        onConfirm: () => {
          tow.metadata.status = 'finalised';
        },
      },
    });
  }
}
