<mat-card>
  <mat-card-header class="between">
    <mat-card-title style="margin: 0">Tow List</mat-card-title>
    <button
      mat-flat-button
      routerLink="/tows/create"
      [checkPermission]="['recordingEvent', 'edit']"
    >
      Create
    </button>
  </mat-card-header>
  <mat-card-content>
    <table
      mat-table
      [dataSource]="dataSource$"
      matSort
      matSortActive="updatedAt"
      matSortDirection="desc"
      cdkDropList
      cdkDropListOrientation="horizontal"
      (cdkDropListDropped)="listDropped($event)"
    >
      <ng-container matColumnDef="metadata.oldTowBaseId">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>
          <span *ngIf="!isLoading">ID</span>
        </th>
        <td mat-cell *matCellDef="let item">{{ item.metadata?.oldTowBaseId }}</td>
      </ng-container>

      <ng-container matColumnDef="metadata.ship">
        <th mat-header-cell *matHeaderCellDef>
          <span *ngIf="!isLoading">Ship</span>
        </th>
        <td mat-cell *matCellDef="let item">{{ item.metadata?.ship?.name }}</td>
      </ng-container>

      <ng-container matColumnDef="metadata.status">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>
          <span *ngIf="!isLoading">Status</span>
        </th>
        <td mat-cell *matCellDef="let item">
          <app-status-chip *ngIf="item.metadata?.status" [value]="item.metadata.status" />
        </td>
      </ng-container>

      <!-- Sorting does not work easily due to the splitting of month/year -->
      <ng-container matColumnDef="metadata.year">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>
          <span *ngIf="!isLoading">Date</span>
        </th>
        <td mat-cell *matCellDef="let item">
          <span *ngIf="item.metadata?.month">
            {{ towsService.toDate(item) | date: 'MMM' : 'UTC' }}
          </span>
          <span *ngIf="item.metadata?.year">{{
            towsService.toDate(item) | date: 'y' : 'UTC'
          }}</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="metadata.validity">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>
          <span *ngIf="!isLoading">Validity</span>
        </th>
        <td mat-cell *matCellDef="let item">
          <span *ngIf="item.metadata.validity">{{ item.metadata.validity }}</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="metadata.message">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>
          <span *ngIf="!isLoading">Comment</span>
        </th>
        <td mat-cell *matCellDef="let item">
          <span *ngIf="item.metadata.message">{{ item.metadata.message }}</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="updatedAt">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>
          <span *ngIf="!isLoading">Last Updated</span>
        </th>
        <td mat-cell *matCellDef="let item">
          <span *ngIf="item.updatedAt">{{ item.updatedAt | date: 'medium' : 'UTC' }}</span>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="headings"></tr>
      <tr
        mat-row
        class="row clickable"
        [class.hidden]="isLoading"
        *matRowDef="let row; columns: headings"
        [routerLink]="row._id"
      ></tr>

      <tr class="mat-row" *matNoDataRow [hidden]="isLoading">
        <td class="mat-cell" [attr.colspan]="headings.length">
          <span>No matching data.</span>
        </td>
      </tr>
    </table>

    @if (isLoading) {
      <suvo-bi-skeleton-table-rows [rowCount]="pageSize" [columnWidths]="widths" />
    }

    <div class="table-footer">
    <app-export-with-filters
        [filtersChannel]="filtersChannel"
        [dataSourceAlias]="filtersChannel?.channelName"
        [exportFetch]="exportFetchWrapper()"
      ></app-export-with-filters>

    <mat-paginator [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions" showFirstLastButtons />
  </div>
  </mat-card-content>
</mat-card>
