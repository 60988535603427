import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { IRecordingEvent } from '../tows/interface/recording-event.interface';
import { PrintControlsComponent } from './print-controls.component';

@Component({
  standalone: true,
  imports: [CommonModule, PrintControlsComponent],
  selector: 'app-printout-host',
  template: `
    @if (controls) {
      <app-print-controls />
    }

    @if (pageBreak) {
      <div style="page-break-after: always"></div>
    }

    <div class="preview-box" [class.ignore]="!previewBox">
      @if (header) {
        <div class="printout-header">
          @if (tow?.metadata; as md) {
            <div>
              <p><b>Tow ID</b></p>
              <p><b>Tow Serial</b></p>
              <p><b>Ship</b></p>
            </div>
            <div>
              <p>{{ md.oldTowBaseId ?? 'N/A' }}</p>
              <p>{{ md.serialNumberPerCalendarMonth }}#{{ md.month }}#{{ md.year }}</p>
              <p>{{ md.ship?.name ?? 'N/A' }}</p>
            </div>
            <div style="text-align: center">
              <img src="/assets/logo/cpr-full.svg" height="100%" style="padding: 0 96px" />
            </div>
            <div>
              <p><b>CPR Internal</b></p>
              <p><b>Prop Setting</b></p>
              <p><b>Validity</b></p>
            </div>
            <div>
              <p>{{ md.internalId ?? 'N/A' }}</p>
              <p>{{ md.propSetting ?? 'N/A' }}</p>
              <p>{{ md.validity ?? 'N/A' }}</p>
            </div>
          }
        </div>
      }

      <div style="margin: 48px 0 32px">
        <ng-content />
      </div>

      @if (footer) {
        <div class="printout-footer">
          <p>
            <b>Printed by</b>
            {{ name }}
          </p>
          <b>{{ title }}</b>
          <p>
            <b>Printed on</b>
            {{ getDate() }}
          </p>
        </div>
      }
    </div>
  `,
  styles: [
    `
      :host {
        --border: 1px solid rgba(0, 0, 0, 0.12);
      }

      .preview-box {
        max-width: 960px;
        margin: 16px auto;
        padding: 64px;

        border: var(--border);
      }
      .ignore {
        display: contents;
      }

      @media print {
        .preview-box {
          display: contents;
        }
      }

      .printout-header {
        display: flex;
        justify-content: space-between;
        height: 96px;
      }
      .printout-header > div {
        flex: 1;
      }
      .printout-header p {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 150px;
      }

      .printout-footer {
        display: flex;
        justify-content: space-between;
        height: 32px;
      }
      .printout-footer b {
        padding-right: 24px;
      }

      :host::ng-deep table {
        border-collapse: collapse;
      }
      :host::ng-deep th {
        text-align: left;
      }
      :host::ng-deep tr {
        border-bottom: var(--border);
      }
    `,
  ],
})
export class PrintoutHostComponent {
  @Input() previewBox = true;
  @Input() controls = true;
  @Input() footer = true;
  @Input() header = true;
  @Input() pageBreak = true;
  @Input() title: string;

  @Input() tow: IRecordingEvent<'tow'>;

  name: string;

  constructor(authService: AngularFireAuth) {
    authService.currentUser.then((u) => (this.name = u.displayName));
  }

  getDate() {
    const date = new Date();
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }
}
