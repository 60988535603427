import { Component, computed, input, Input } from '@angular/core';

@Component({
  selector: 'app-pci-colour',
  template: `
    @if (value() !== undefined) {
      <div style="display: flex; align-items: center; gap: 16px">
        <span
          style="min-height: 16px; min-width: 16px; border: 1px solid rgba(0, 0, 0, 0.5)"
          [style.background-color]="color()"
          [matTooltip]="showLabel === 'tooltip' ? label() : undefined"
          [matTooltipPosition]="matTooltipPosition"
        ></span>
        @if (showLabel === 'label') {
          <span>{{ label() }}</span>
        }
      </div>
    }
  `,
})
export class PciColourComponent {
  readonly value = input<number>();

  @Input() showLabel: 'label' | 'tooltip' | 'none' = 'label';
  @Input() matTooltipPosition = 'right';

  readonly color = computed(() => {
    if (typeof this.value() === 'number') {
      return 'rgba(0, 192, 64, ' + Math.floor(this.value() * 1.5) / 7 + ')';
    } else {
      return 'indianred';
    }
  });

  readonly label = computed(() => {
    if (typeof this.value() === 'number') {
      return this.value();
    } else {
      return 'Unsuitable';
    }
  });
}
