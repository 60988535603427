<app-data-entry-check-block-panel>
  <app-header>
    <app-sample-data-entry-sample-switcher />

    <a mat-flat-button routerLink="..">Sample Analysis Overview</a>

    @if (!sample?.finalisedAt) {
      <button
        mat-stroked-button
        (click)="sample.isFinalising ? cancelFinalising() : finaliseSample()"
        [checkPermission]="['sample', 'finalise']"
      >
        {{ sample?.isFinalising ? 'Cancel Finalising' : 'Finalise' }}
      </button>
    }
  </app-header>

  @if (sample) {
    <section class="row">
      <mat-card>
        <mat-card-header>
          <mat-card-title>Key information</mat-card-title>
        </mat-card-header>
        <mat-card-content class="key-info">
          <div>
            <h3>Tow</h3>
            <p>{{ tow.metadata?.oldTowBaseId ?? tow?._id }}</p>
          </div>
          <div>
            <h3>Sample no.</h3>
            <p>{{ sample.sampleNo }}</p>
          </div>
          <div>
            <h3>Location</h3>
            <p>{{ sample.sampleLatitude }}, {{ sample.sampleLongitude }}</p>
          </div>
          <div>
            <h3>Midpoint (UTC)</h3>
            <p>{{ sample.sampleMidpointDate | date: 'medium' : 'UTC' }}</p>
          </div>
          <div>
            <h3>Midpoint (Local)</h3>
            <p>{{ getLocaltime() | date: 'medium' : 'UTC' }}</p>
          </div>
          <div>
            <h3>Day/Night</h3>
            <p>{{ getDayNight() }}</p>
          </div>
          <div>
            <h3>PCI colour</h3>
            <app-pci-colour [value]="sample.chlorophyllIndex"></app-pci-colour>
          </div>
          <div>
            <h3>Factor</h3>
            <p>{{ sample.factor }}</p>
          </div>
          <div>
            <h3>Analyst</h3>
            <app-user-avatar [userId]="sample.userId"></app-user-avatar>
          </div>
          <div>
            <h3>Status</h3>
            <app-status-chip
              [value]="
                sample.finalisedAt ? 'finalised' : sample.isFinalising ? 'finalising' : 'analysing'
              "
            />
          </div>
        </mat-card-content>
      </mat-card>

      <div style="flex-basis: 50%">
        <app-tow-log-map-tile [sample]="sample" />
      </div>
    </section>
  }

  <section>
    <app-data-entry-check-blocks />
  </section>

  <section>
    <app-sample-details-form [sample]="sample" />
  </section>

  <section>
    @if (measurementsAreLoading) {
      <suvo-core-loading-spinner />
    } @else {
      <mat-card class="measurements">
        <mat-card-header>
          <mat-card-title>Measurements</mat-card-title>
        </mat-card-header>

        <mat-card-content>
          <div style="display: flex; justify-content: space-between; gap: 22px">
            <div>
              <div>
                <mat-button-toggle-group
                  [(value)]="countingMethod"
                  (change)="onCountingMethodChange($event)"
                >
                  <mat-button-toggle value="PHYTO">Phyto</mat-button-toggle>
                  <mat-button-toggle value="TRAVERSE">Traverse</mat-button-toggle>
                  <mat-button-toggle value="EYECOUNT">Eyecount</mat-button-toggle>
                </mat-button-toggle-group>
              </div>
              <br />
              <p style="opacity: 70%; margin-top: 0">
                Use the form below to add measurements to the sample. The measurements that have
                been added to the sample appear in the left hand panel. Group totals are
                auto-calculated and displayed as a hierarchy in the right hand panel.
              </p>
            </div>

            <div style="width: 100%; max-width: 600px; padding-top: 60px">
              <app-abundance-categories [countingMethod]="countingMethod" />
            </div>
          </div>

          <div style="margin: 32px 0">
            @if (sample) {
              <div class="measurement-form-container">
                <h3 style="text-align: center; margin-top: 10px">Add measurement</h3>
                @if (!dataEntryEnabled) {
                  <div style="color: #bdbdbd; text-align: center">
                    @if (sample.finalisedAt) {
                      Data entry is disabled as this sample is now finalised.
                    } @else if (!sample.userId) {
                      Data entry is currently disabled as this sample has not been allocated.
                    } @else if (sample.userId !== sample.currentUser?._id) {
                      Data entry is disabled as this sample is allocated to a different analyst.
                    }
                    <br />
                    <button
                      mat-flat-button
                      [checkPermission]="['sample', 'allocate']"
                      (click)="dataEntryEnabled = true; measurementFormGroup.enable()"
                      style="margin-top: 12px"
                    >
                      Senior analyst override
                    </button>
                  </div>
                } @else {
                  <form
                    [formGroup]="measurementFormGroup"
                    class="measurement-form"
                    [checkPermission]="['sample', 'analyse']"
                    style="display: flex; gap: var(--global-padding)"
                  >
                    <mat-form-field style="width: 100%">
                      <mat-label>Taxon</mat-label>
                      <input
                        #taxonInput
                        required
                        matInput
                        formControlName="taxon"
                        [matAutocomplete]="auto"
                        (input)="onInput()"
                      />
                    </mat-form-field>
                    <mat-form-field style="max-width: 150px">
                      <mat-label>Quantity</mat-label>
                      <input required matInput formControlName="quantity" />
                      @if (measurementFormGroup.controls.quantity.invalid) {
                        <mat-error>Please enter a positive integer or a '+'.</mat-error>
                      }
                    </mat-form-field>
                    <button
                      mat-flat-button
                      [disabled]="measurementFormGroup.invalid"
                      (click)="addMeasurement()"
                      style="margin: 3.5px 0 22.313px"
                    >
                      Add
                    </button>
                  </form>
                  <div>
                    <suvo-error-list [errors]="errors" />
                  </div>
                }
              </div>
            }
          </div>

          <div fxLayout="row" fxLayoutAlign="center" fxLayoutGap="96px">
            <div>
              <div class="list-heading">
                <span style="flex-grow: 1"><br />Added Taxon Measurements</span>
                <span style="padding-right: 12px; text-align: right"
                  >Category<br />(Factor Applied)</span
                >
                <span style="padding-right: 58px; text-align: right"><br />Count</span>
              </div>
              @for (measurement of processedMeasurements; track measurement) {
                @if (measurement.taxon.countingMethod === countingMethod) {
                  <app-measurement-list-item
                    [sample]="sample"
                    [taxon]="measurement.taxon"
                    [measurement]="measurement.measurement"
                    [bracketText]="
                      measurement.other ? (countingMethod === 'PHYTO' ? '' : 'extra') : null
                    "
                    (delete)="pull()"
                    (update)="pull()"
                  />
                }
              }
              @if (!hasMeasurementsForCountingMethod(countingMethod)) {
                <div class="note">No taxons have been added for this counting type.</div>
              }
            </div>
            <div>
              <div>
                <div class="list-heading">
                  <span style="flex-grow: 1"><br />Calculated Taxon Totals</span>
                  <span style="padding-right: 8px; text-align: right"
                    >Category<br />(Factor Applied)</span
                  >
                  <span style="min-width: 40px; text-align: right"><br />Count</span>
                </div>
                <app-taxon-hierarchy
                  [sample]="sample"
                  [countingMethod]="countingMethod"
                  [measurements]="measurements ?? []"
                  [showCategories]="true"
                />
              </div>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    }
  </section>

  <mat-autocomplete #auto="matAutocomplete" [displayWith]="getTaxonDisplayValue">
    <ng-container *ngFor="let option of taxonSearch">
      <mat-option *ngIf="option.countingMethod === countingMethod" [value]="option">
        <span>{{ option.taxonName }}</span>
        <span style="opacity: 50%"> (ID: {{ option.cprTaxonId }})</span>
        <span style="color: #00f8" *ngIf="option.taxonChildren?.length">
          {{ countingMethod === 'PHYTO' ? '' : '(extra)' }}</span
        >
      </mat-option>
    </ng-container>
  </mat-autocomplete>
</app-data-entry-check-block-panel>

<ng-template #confirmAcceptedIdDialog>
  <div style="padding: 20px">
    <h3>Taxon no longer in use</h3>
    <div>
      {{ acceptedIdErrorMessage }}
    </div>
    <br />
    <div>Do you wish to switch to the accepted taxa name?</div>
    <br />
    <div style="width: 100%; display: flex; justify-content: space-between">
      <button mat-button (click)="closeConfirmAcceptedIdDialog(false)">Cancel</button>
      <button mat-flat-button (click)="closeConfirmAcceptedIdDialog(true)">Continue</button>
    </div>
  </div>
</ng-template>
