import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';

@Component({
  standalone: true,
  imports: [MatButtonModule],
  selector: 'app-print-controls',
  template: `
    <div>
      <button mat-button (click)="back()">Back</button>
      <button mat-flat-button (click)="print()">Print (Ctrl+P)</button>
    </div>
  `,
  styles: [
    `
      div {
        display: flex;
        justify-content: space-between;
        padding: 16px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);
      }

      @media print {
        div {
          display: none;
        }
      }
    `,
  ],
})
export class PrintControlsComponent {
  back() {
    window.history.back();
  }

  print() {
    window.print();
  }
}
