<mat-sidenav-container autosize>
  <mat-sidenav
    #panel
    mode="side"
    autoFocus="false"
    fixedInViewport="true"
    position="end"
    style="padding: var(--global-padding)"
    [opened]="!!viewing"
  >
    <div class="between" style="width: 100%">
      <h3 style="padding: 0">Measurement Details</h3>
      <button mat-icon-button (click)="viewing = undefined">
        <mat-icon>close</mat-icon>
      </button>
    </div>

    @if (viewing) {
      @if (viewing.sample) {
        <p>
          Sample No: <b>{{ viewing.sample.sampleNo }}</b>
          <br />
          Midpoint: <b>{{ viewing.sample.sampleMidpointDate | date: 'medium' : 'UTC' ?? 'N/A' }}</b>
        </p>
      }

      <p>
        @if (viewing.measurement) {
          {{ viewing.measurement.taxonName }}
        }
        @if (viewing.measurement) {
          <br />
          Count: <b>{{ viewing.measurement.analysedCount ?? 'N/A' }}</b>
        }
        @if (viewing.abundance) {
          <br />
          Abundance: <b>{{ viewing.abundance ?? 'N/A' }}</b>
        }
      </p>

      <app-check-block-conversation
        [sampleId]="viewing.sample._id"
        [taxonId]="viewing.taxon._id"
        (deleted)="loadMeasurements()"
        (reassigned)="loadMeasurements()"
        (resolved)="loadMeasurements()"
        (raised)="loadMeasurements()"
      />
    }
  </mat-sidenav>

  <mat-sidenav-content>
    <app-header>
      <a mat-flat-button [routerLink]="['/tows', tow._id, 'checkblocks', 'print']">
        Print Check Blocks
      </a>
      @if (tow.metadata.status !== 'finalised') {
        <button
          mat-flat-button
          (click)="finaliseTow()"
          [checkPermission]="['recordingEvent', 'finalise']"
        >
          Finalise Tow
        </button>
      }
    </app-header>

    <mat-card class="grid-container" [style.--label-width]="expandLabels ? '300px' : '150px'">
      @if (loading) {
        <div class="loading-overlay">
          <mat-spinner />
        </div>
      } @else {
        <div class="expand-btn">
          <button mat-raised-button (click)="expandLabels = !expandLabels">
            <mat-icon>{{ expandLabels ? 'chevron_left' : 'chevron_right' }}</mat-icon>
          </button>
        </div>
        <div class="grid sticky">
          <div class="col sticky">
            <div class="label">Sample No.</div>
          </div>
          <div class="col" *ngFor="let sample of samples">
            <div
              class="cell"
              style="cursor: pointer"
              [routerLink]="sample._id"
              [class.finalised-sample]="sample.finalisedAt"
            >
              {{ sample.sampleNo }}
            </div>
          </div>
        </div>

        <div class="grid">
          <div class="col sticky">
            <div class="label">Day/Night</div>
            <div class="label">Analyst</div>
            <div class="label">Microscope No.</div>
          </div>
          @for (sample of samples; track $index) {
            <div class="col">
              <div class="cell">
                {{ getDayOrNight(sample) }}
              </div>
              <div class="cell">
                <app-user-avatar [userId]="sample.userId" [showLabel]="false" />
              </div>
              <div class="cell">
                @if (sample.microscope?.oldMicroscopeId) {
                  {{ sample.microscope.oldMicroscopeId }}
                }
              </div>
            </div>
          }
        </div>

        @for (section of orderedGridData; track $index) {
          @if (section.values.length) {
            <div class="row" style="border-top: 3px solid var(--border-color)">
              <div
                class="label sticky"
                style="font-weight: bold; background-color: transparent; border: none"
              >
                {{ section.name }}
              </div>
            </div>
            @for (row of section.values; track $index) {
              <div class="row">
                <div
                  class="label sticky"
                  [style.font-weight]="row.taxon.taxonChildren?.length ? 'bold' : ''"
                >
                  {{ row.taxon.taxonName }}
                </div>
                @for (cell of row.values; track $index) {
                  <app-analysis-overview-cell-clickable
                    [cell]="cell"
                    [active]="viewing === cell"
                    (click)="viewing = cell"
                  />
                }
              </div>
            }
          }
        }
      }
    </mat-card>
  </mat-sidenav-content>
</mat-sidenav-container>
