export const FINALISING_FACTORS: [numberOfFields: number, factor: number, value: number][] = [
  [1, 0.75, 0],
  [1, 0.8, 0],
  [1, 0.9, 0],
  [1, 1.1, 0],
  [1, 1.2, 0],
  [1, 1.25, 0],
  [2, 0.75, 0],
  [2, 0.8, 0],
  [2, 0.9, 0],
  [2, 1.1, 0],
  [2, 1.2, 0],
  [2, 1.25, 0],
  [3, 0.75, 0],
  [3, 0.8, 0],
  [3, 0.9, 0],
  [3, 1.1, 0],
  [3, 1.2, 0],
  [3, 1.25, 0],
  [4, 0.75, 1],
  [4, 0.8, 0],
  [4, 0.9, 0],
  [4, 1.1, 0],
  [4, 1.2, 0],
  [4, 1.25, 1],
  [5, 0.75, 2],
  [5, 0.8, 1],
  [5, 0.9, 1],
  [5, 1.1, 1],
  [5, 1.2, 1],
  [5, 1.25, 2],
  [6, 0.75, 2],
  [6, 0.8, 1],
  [6, 0.9, 1],
  [6, 1.1, 1],
  [6, 1.2, 1],
  [6, 1.25, 2],
  [7, 0.75, 1],
  [7, 0.8, 1],
  [7, 0.9, 1],
  [7, 1.1, 1],
  [7, 1.2, 1],
  [7, 1.25, 2],
  [8, 0.75, 2],
  [8, 0.8, 1],
  [8, 0.9, 1],
  [8, 1.1, 1],
  [8, 1.2, 1],
  [8, 1.25, 2],
  [9, 0.75, 2],
  [9, 0.8, 1],
  [9, 0.9, 1],
  [9, 1.1, 1],
  [9, 1.2, 1],
  [9, 1.25, 2],
  [10, 0.75, 2],
  [10, 0.8, 1],
  [10, 0.9, 1],
  [10, 1.1, 1],
  [10, 1.2, 1],
  [10, 1.25, 2],
  [11, 0.75, 2],
  [11, 0.8, 1],
  [11, 0.9, 1],
  [11, 1.1, 1],
  [11, 1.2, 1],
  [11, 1.25, 2],
  [12, 0.75, 2],
  [12, 0.8, 1],
  [12, 0.9, 1],
  [12, 1.1, 1],
  [12, 1.2, 1],
  [12, 1.25, 2],
  [13, 0.75, 2],
  [13, 0.8, 1],
  [13, 0.9, 1],
  [13, 1.1, 1],
  [13, 1.2, 1],
  [13, 1.25, 2],
  [14, 0.75, 2],
  [14, 0.8, 1],
  [14, 0.9, 1],
  [14, 1.1, 1],
  [14, 1.2, 1],
  [14, 1.25, 2],
  [15, 0.75, 2],
  [15, 0.8, 1],
  [15, 0.9, 1],
  [15, 1.1, 1],
  [15, 1.2, 1],
  [15, 1.25, 2],
  [16, 0.75, 1],
  [16, 0.8, 1],
  [16, 0.9, 1],
  [16, 1.1, 1],
  [16, 1.2, 1],
  [16, 1.25, 1],
  [17, 0.75, 1],
  [17, 0.8, 1],
  [17, 0.9, 1],
  [17, 1.1, 1],
  [17, 1.2, 1],
  [17, 1.25, 1],
  [18, 0.75, 1],
  [18, 0.8, 1],
  [18, 0.9, 0],
  [18, 1.1, 0],
  [18, 1.2, 1],
  [18, 1.25, 1],
  [19, 0.75, 1],
  [19, 0.8, 0],
  [19, 0.9, 0],
  [19, 1.1, 0],
  [19, 1.2, 0],
  [19, 1.25, 1],
  [20, 0.75, 0],
  [20, 0.8, 0],
  [20, 0.9, 0],
  [20, 1.1, 0],
  [20, 1.2, 0],
  [20, 1.25, 0],
  [1, 1, 0],
  [2, 1, 0],
  [3, 1, 0],
  [4, 1, 0],
  [5, 1, 0],
  [6, 1, 0],
  [7, 1, 0],
  [8, 1, 0],
  [9, 1, 0],
  [10, 1, 0],
  [11, 1, 0],
  [12, 1, 0],
  [13, 1, 0],
  [14, 1, 0],
  [15, 1, 0],
  [16, 1, 0],
  [17, 1, 0],
  [18, 1, 0],
  [19, 1, 0],
  [20, 1, 0],
];
