import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { FinalisationCheckResult } from '@cpr/shared/finalisation';
import { environment } from 'apps/mba-cpr-survey-portal/src/environments/environment';
import { lastValueFrom } from 'rxjs';
import { TableApiService } from 'submodules/frontend-framework/SUVO-BI-CLIENT-LIB/projects/suvo-bi-client-lib/src/lib/features/tables/services/table-api.service';
import { ITableOptions } from 'submodules/frontend-framework/SUVO-BI-CLIENT-LIB/projects/suvo-bi-client-lib/src/lib/shared/interfaces/table/table-options.interface';
import { ConfirmDialogComponent } from '../../../../shared/components/confirm-dialog/confirm-dialog.component';
import { ICheckBlock } from '../../check-block/interface/check-block.interface';
import { IMeasurement } from '../../measurement/measurement.interface';
import { ITaxon } from '../../taxon/taxon.interface';
import { IAnalysisSample } from '../interface/analysis-sample.interface';
import { ISample } from '../interface/sample.interface';

@Injectable({ providedIn: 'root' })
export class SamplesService extends TableApiService<ISample> {
  constructor(
    http: HttpClient,
    private readonly dialog: MatDialog,
  ) {
    super(http, `${environment.cprServiceApi}sample/`);
  }

  getPaginated(prefixes: string | string[], options: ITableOptions) {
    return super.getPaginated(prefixes, options);
  }

  generate(towId: string) {
    return lastValueFrom(
      this.httpClient.post(`${environment.cprServiceApi}sample/generate/${towId}`, null),
    );
  }

  generateWithDialog(towId: string, onSuccess?: () => void) {
    this.dialog.open(ConfirmDialogComponent, {
      data: {
        onConfirm: async () => {
          await this.generate(towId);
          onSuccess?.();
        },
        title: 'Generate samples',
        body: 'This action will generate cutting points and samples from the tow log information.',
        confirmButtonLabel: 'Generate',
      },
    });
  }

  analyseMany(samples: Pick<ISample, '_id' | 'chlorophyllIndex' | 'isNotAnalyseable'>[]) {
    return lastValueFrom(
      this.httpClient.patch(`${environment.cprServiceApi}sample/analyse`, { samples }),
    );
  }

  allocateMany(samples: Pick<ISample, '_id' | 'userId'>[]) {
    return lastValueFrom(
      this.httpClient.patch(`${environment.cprServiceApi}sample/allocate`, { samples }),
    );
  }

  updateSampleDetails(id: string, details: Pick<ISample, 'microscopeId' | 'comment'>) {
    return lastValueFrom(
      this.httpClient.patch(`${environment.cprServiceApi}sample/${id}/details`, details),
    );
  }

  check(sampleId: string) {
    return lastValueFrom(
      this.httpClient.get<FinalisationCheckResult[]>(
        `${environment.cprServiceApi}sample/${sampleId}/finalise`,
      ),
    );
  }

  finalise(sampleId: string) {
    return lastValueFrom(
      this.httpClient.post(`${environment.cprServiceApi}sample/${sampleId}/finalise`, {}),
    );
  }

  unfinalise(sampleId: string) {
    return lastValueFrom(
      this.httpClient.post(`${environment.cprServiceApi}sample/${sampleId}/unfinalise`, {}),
    );
  }

  checkTow(towId: string) {
    return lastValueFrom(
      this.httpClient.get<Record<number, FinalisationCheckResult[]>>(
        `${environment.cprServiceApi}sample/${towId}/finalise/tow`,
      ),
    );
  }

  finaliseTow(towId: string) {
    return lastValueFrom(
      this.httpClient.post(`${environment.cprServiceApi}sample/${towId}/finalise/tow`, {}),
    );
  }

  getCheckBlocks(sampleID: string) {
    return this.get<ICheckBlock[]>(`${sampleID}/check-blocks`);
  }

  getMeasurements(sampleID: string) {
    return this.get<Array<IMeasurement & { taxon: ITaxon }>>(`${sampleID}/measurements`);
  }

  putMeasurements(sampleID: string, measurements: IMeasurement[]) {
    return lastValueFrom(
      this.httpClient.put(`${environment.cprServiceApi}sample/${sampleID}/measurements`, {
        measurements,
      }),
    );
  }

  getAnalysis(id: string) {
    return this.get<IAnalysisSample>(`${id}/analysis`);
  }

  // RANDOM ALLOC DISABLED PRIOR TO UAT 2024-10-30

  // autoAllocate(towID: string, samples: string[], callback?: () => void) {
  //   this.dialog.open(ConfirmDialogComponent, {
  //     data: {
  //       onConfirm: () => {
  //         lastValueFrom(
  //           this.httpClient.post(`${environment.cprServiceApi}sample/allocate/${towID}`, {
  //             samples,
  //           }),
  //         ).then(() => callback?.());
  //       },
  //       title: 'Auto Allocate Samples',
  //       body: `
  //         This action will allocate the selected unfinalised samples evenly amongst all available analysts.
  //         Any selected samples that are already allocated will be re-allocated during this process.
  //         Would you like to proceed?
  //       `,
  //     },
  //   });
  // }
}
