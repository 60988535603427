import { AsyncPipe } from '@angular/common';
import { Component, inject, ViewChild } from '@angular/core';
import { MatMenuModule, MatMenuTrigger } from '@angular/material/menu';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { ICellEditorParams } from 'ag-grid-community';
import { UsersService } from '../../../users/service/users.service';
import { UsersModule } from '../../../users/users.module';

@Component({
  standalone: true,
  imports: [AsyncPipe, MatMenuModule, UsersModule],
  template: `
    <div [matMenuTriggerFor]="menu" (menuClosed)="params.api.stopEditing()"></div>
    <mat-menu #menu="matMenu">
      <button mat-menu-item (click)="select(null)">(unassigned)</button>
      @for (analyst of analysts | async; track $index) {
        <button mat-menu-item (click)="select(analyst._id)">
          <app-user-avatar [userId]="analyst._id" />
        </button>
      }
    </mat-menu>
  `,
})
export class AnalystEditorComponent implements ICellEditorAngularComp {
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

  params: ICellEditorParams;
  value: string;

  analysts = inject(UsersService).analysts;

  agInit(params: ICellEditorParams) {
    this.params = params;
    this.value = params.value;
    setTimeout(() => this.trigger.openMenu());
  }

  getValue() {
    return this.value;
  }

  select(value: string) {
    this.value = value;
    this.params.api.stopEditing();
  }
}
