import { CommonModule } from '@angular/common';
import { Component, inject, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { getFlattenedTowLegs } from '@cpr/shared/tow-log';
import { SharedModule } from '../../../shared/shared.module';
import { TowLogEventCodePipe } from '../tow-log/pipes/tow-log-event-code.pipe';
import { TowLogApiService } from '../tow-log/services/tow-log-api.service';
import { PrintoutHostComponent } from './printout-host.component';

// A constant in the old code
const BLOCK_SIZE = 10;

@Component({
  standalone: true,
  imports: [CommonModule, SharedModule, PrintoutHostComponent, TowLogEventCodePipe],
  selector: 'app-tow-log-printout',
  template: `
    <app-printout-host
      title="Tow Log Report"
      [controls]="standalone"
      [pageBreak]="pageBreak"
      [tow]="tow"
    >
      <table>
        <thead>
          <tr>
            <th>UTC Time</th>
            <th>Event</th>
            <th>Latitude</th>
            <th>Longitude</th>
            <th>Course</th>
            <th>Speed</th>
            <th>Distance</th>
            <th>Comments</th>
          </tr>
        </thead>
        <tbody>
          @for (leg of legs | async; track $index) {
            <tr>
              <td>{{ leg.eventDate | date: 'dd/MM/yyyy HH:mm' : '+0000' }}</td>
              <td>{{ leg.eventCode | towLogEventCode }}</td>
              <td>{{ leg.eventLatitude | coord: 'lat' }}</td>
              <td>{{ leg.eventLongitude | coord: 'lng' }}</td>
              <td>{{ leg.course | number: '1.0-2' }}</td>
              <td>{{ leg.speed | number: '1.0-2' }}</td>
              <td>{{ leg.distance | number: '1.0-2' }}</td>
              <td>{{ leg.comments }}</td>
            </tr>
          }
        </tbody>
      </table>

      <div class="info-container">
        <div class="between">
          <span>Silk Start</span>
          <span>{{ tow.metadata.silkReadingStart }}</span>
        </div>
        <div class="between">
          <span>Towed Miles</span>
          <span>{{ tow.metadata.towMileage | number: '1.0-2' }}</span>
        </div>
        <div class="between">
          <span>Average Speed</span>
          <span>{{ tow.metadata.shipSpeed | number: '1.0-2' }}</span>
        </div>
        <div class="between">
          <span>Silk End</span>
          <span>{{ tow.metadata.silkReadingEnd }}</span>
        </div>
        <div class="between">
          <span>Sampled Miles</span>
          <span>{{ tow.metadata.sampledMileage | number: '1.0-2' }}</span>
        </div>
        <div class="between">
          <span>Miles Per Division</span>
          <span>{{ tow.metadata.milesPerDivision | number: '1.0-2' }}</span>
        </div>
        <div class="between">
          <span>Divisions Run</span>
          <span>{{ tow.metadata.silkReadingEnd - tow.metadata.silkReadingStart }}</span>
        </div>
        <div class="between">
          <span>Divisions per Block</span>
          <span>{{ (getDivisionsPerBlock() | number: '1.0-2') ?? 'N/A' }}</span>
        </div>
      </div>
    </app-printout-host>
  `,
  styles: `
    .info-container {
      display: flex;
      flex-flow: row wrap;
      gap: 4px 64px;
      margin: 32px 0;
    }

    .between {
      flex-basis: 24%;
      border-bottom: var(--border);
    }

    .between > :first-child {
      font-weight: bold;
    }
  `,
})
export class TowLogPrintoutComponent {
  @Input() standalone = true;
  @Input() pageBreak = false;

  readonly tow = inject(ActivatedRoute).snapshot.data.data;

  readonly legs = inject(TowLogApiService)
    .getPaginated('', {
      simpleFilters: { towId: `ObjectId:${this.tow._id}` },
    })
    .then(({ data }) => getFlattenedTowLegs(data));

  getDivisionsPerBlock() {
    return typeof this.tow.metadata.milesPerDivision === 'number'
      ? this.tow.metadata.milesPerDivision / BLOCK_SIZE
      : null;
  }
}
