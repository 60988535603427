import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  ViewChild,
  computed,
  inject,
  signal,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { AuthService } from '@suvo-bi-core';
import { TaxonService } from '../../../taxon/taxon.service';
import { UsersService } from '../../../users/service/users.service';
import { CheckBlockService } from '../../check-block.service';
import { ICheckBlock } from '../../interface/check-block.interface';

@Component({
  selector: 'app-check-block-conversation',
  template: `
    <mat-divider style="margin: 8px 0" />

    @if (checkBlock()?.messages) {
      <header style="display: flex">
        <h3 style="padding-top: 0; padding-bottom: 0; margin-top: 4px; margin-bottom: 0">
          Checkblock
        </h3>

        <div style="margin-left: auto">
          <app-check-block-menu
            [checkBlock]="checkBlock()"
            (deleted)="onDeleted()"
            (reassigned)="onReassigned($event)"
            (resolved)="onResolved()"
          />
        </div>
      </header>

      <app-check-block-assignee
        [checkBlockId]="checkBlock()._id"
        [userId]="checkBlock().userId"
        (userIdChange)="onReassigned($event)"
      />

      <div style="margin-top: 20px">
        @for (message of checkBlock().messages; track message._id) {
          <app-check-block-message
            [message]="message"
            [me]="message.userId === currentUserID() | async"
          />
        }
      </div>
    }

    @if (checkBlock() || raiseButtonClicked) {
      <div style="display: flex; margin-top: var(--global-padding)">
        @if (checkBlock()?.resolved || checkBlock()?.result) {
          <span style="text-align: center; color: grey">
            This check block has been marked as resolved with action {{ checkBlock().result }}.
          </span>
        } @else if (!sendMessageAllowed) {
          <span style="text-align: center; color: grey">
            You do not have permission to send messages in this conversion.
          </span>
        } @else {
          <mat-form-field floatLabel="never" style="flex-grow: 1">
            <textarea
              #messageInput
              matInput
              required
              minlength="1"
              [formControl]="messageForm"
              (input)="messageForm.markAsUntouched()"
              (blur)="messageForm.markAsUntouched()"
              (keyup.enter)="addMessage()"
            ></textarea>
          </mat-form-field>
          <button
            mat-icon-button
            style="margin-top: 9px"
            [disabled]="!messageForm.valid"
            (click)="addMessage()"
          >
            <mat-icon>send</mat-icon>
          </button>
        }
      </div>
    } @else if (isSenior() | async) {
      <button mat-flat-button style="width: 100%" (click)="raise()">Raise checkblock</button>
    } @else {
      <span style="text-align: center; color: grey">
        You do not have permission to raise a check block.
      </span>
    }
  `,
})
export class CheckBlockConversationComponent implements OnChanges, OnDestroy {
  private readonly authService = inject(AuthService);
  private readonly userService = inject(UsersService);

  readonly currentUserID = computed(() =>
    this.authService.currentUser()
      ? this.userService.getMe().then((u) => u._id)
      : Promise.resolve(),
  );

  readonly isSenior = computed(() =>
    this.authService
      .currentUserContext()
      .then((ctx) => !!ctx?.permissions?.checkblocks?.includes('create')),
  );

  @ViewChild('messageInput') messageInput: ElementRef<HTMLTextAreaElement>;

  @Input() sampleId: string;
  @Input() taxonId: string;

  @Output() deleted = new EventEmitter<void>();
  @Output() raised = new EventEmitter<void>();
  @Output() reassigned = new EventEmitter<string>();
  @Output() resolved = new EventEmitter<void>();

  readonly messageForm = new FormControl<string>('');

  currentUserId: string;
  sendMessageAllowed = false;

  readonly checkBlock = signal<ICheckBlock<true>>(null);
  readonly assignedTo = computed(() => {
    const userId = this.checkBlock()?.userId;
    return userId ? this.userService.getByUserId(userId) : Promise.resolve();
  });

  raiseButtonClicked = false;

  pollTimeout: string | number | NodeJS.Timeout;

  constructor(
    private readonly checkBlockService: CheckBlockService,
    private readonly taxonService: TaxonService,
  ) {}

  async updatePermissions() {
    this.sendMessageAllowed = await this.isSenior();

    if (!this.sendMessageAllowed) {
      this.sendMessageAllowed = this.checkBlock()?.userId === this.currentUserId;
    }

    if (!this.checkBlock()?.resolved && this.sendMessageAllowed) {
      this.messageForm.enable();
    } else {
      this.messageForm.disable();
    }
  }

  async ngOnChanges() {
    await this.pull();
    this.messageForm.reset();
    this.updatePermissions();
    this.raiseButtonClicked = false;
  }

  ngOnDestroy() {
    this.stopPolling();
  }

  stopPolling() {
    if (this.pollTimeout) {
      clearInterval(this.pollTimeout);
    }
  }

  startPolling() {
    this.stopPolling();
    this.pollTimeout = setInterval(() => this.pull(), 10000);
  }

  async pull() {
    this.checkBlock.set(
      await this.checkBlockService.getBySampleAndTaxon(this.sampleId, this.taxonId),
    );
    if (this.checkBlock()) {
      this.startPolling(); // Reset countdown
    } else {
      this.stopPolling();
    }
  }

  async addMessage() {
    if (this.checkBlock()?.messages.length) {
      await this.checkBlockService.addMessage(this.sampleId, this.taxonId, this.messageForm.value);
    } else {
      await this.checkBlockService.raiseCheckBlock(
        this.sampleId,
        this.taxonId,
        this.messageForm.value,
      );
      this.raised.emit();
    }
    this.messageForm.reset();
    this.pull();
  }

  async raise() {
    const taxon = await this.taxonService.getById(this.taxonId);
    this.messageForm.setValue(`Any ${taxon.taxonName}? Please confirm.`);
    this.raiseButtonClicked = true;
    setTimeout(() => this.messageInput.nativeElement.focus());
  }

  onReassigned(userId: string) {
    void this.pull();
    this.reassigned.emit(userId);
  }

  onDeleted() {
    this.checkBlock.set(null);
    this.deleted.emit();
  }

  onResolved() {
    void this.pull();
    this.resolved.emit();
  }
}
