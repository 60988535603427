export const CHECK_BLOCK_RESULTS = {
  NO_CHANGE: {
    label: 'No change',
    description: 'There was no change to the original analysis data',
    isError: false,
  },
  MISIDENTIFIED_ERR: {
    label: 'Misidentified',
    description: 'The species was misidentified',
    isError: true,
  },
  COUNT_ERR: {
    label: 'Error with count',
    description: 'There was an error with the abundance entry',
    isError: true,
  },
  DATA_ENTRY_ERR: {
    label: 'Data entry error',
    description: 'The was an error entering the data into the computer',
    isError: true,
  },
  ACCEPTABLE: {
    label: 'Acceptable Error',
    description:
      'Due to only analysing a subsection of the sample this error is not outside acceptable limits',
    isError: false,
  },
  MISCOUNT_MISIDENTIFIED_ERR: {
    label: 'Miscount and Misidentified',
    description:
      'The species was miscounted and misidentified (could highlight training requirement)',
    isError: true,
  },
  UNCONFIRMED: {
    label: 'Could not be confirmed',
    description: 'Recorded in orginal analysis but not found on check block',
    isError: false,
  },
  NOT_IN_ANALYSIS_ERR: {
    label: 'Error not seen in orginal analysis',
    description:
      'Consider number of taxon missed (Phyto/Traverse) or single missed taxa from eycount during original analysis, but found on check block',
    isError: true,
  },
  REANALYSIS_MISIDENTIFIED_ERR: {
    label: 'Reanalysis Misidentified',
    description: 'Misidentified',
    isError: true,
  },
  REANALYSIS_COUNT_ERR: {
    label: 'Reanalysis Error with count',
    description: 'Error with count',
    isError: true,
  },
  REANALYSIS_DATA_ENTRY_ERR: {
    label: 'Reanalysis Data entry error',
    description: 'Data entry error',
    isError: true,
  },
  REANALYSIS_MISCOUNT_MISIDENTIFIED_ERR: {
    label: 'Reanalysis Miscount and Misidentified',
    description: 'Miscount and Misidentified',
    isError: true,
  },
  REANALYSIS_UNCONFIRMED: {
    label: 'Reanalysis Could not be confirmed',
    description: 'Could not be confirmed',
    isError: false,
  },
  REANALYSIS_NOT_IN_ANALYSIS_ERR: {
    label: 'Reanalysis Error not seen in orginal analysis',
    description: 'Error not seen in orginal analysis',
    isError: true,
  },
} as const;

export const CHECK_BLOCK_RESULT_CODES = Object.keys(CHECK_BLOCK_RESULTS);

export type TCheckBlockResultCode = keyof typeof CHECK_BLOCK_RESULTS;
