<h2 mat-dialog-title>Finalise Tow</h2>

<mat-dialog-content>
  <mat-horizontal-stepper [linear]="true" #stepper>
    <mat-step [stepControl]="checksValid">
      <ng-template matStepLabel>Tow sample checks</ng-template>
      @if (checks | async; as checks) {
        @if (!(checksValid | async)) {
          <p style="color: indianred">Cannot finalise due to errors - see below.</p>
        }
        @for (sample of checks | keyvalue; track $index) {
          <h3>Sample {{ sample.key }}</h3>
          <app-finalisation-report [report]="sample.value" />
        }
      } @else {
        <mat-spinner />
      }
    </mat-step>
    <mat-step [stepControl]="checksValid">
      <ng-template matStepLabel>Tow log review</ng-template>
      @if (tow) {
        <app-tow-log-table [tow]="tow" />
      }
    </mat-step>
    <mat-step [stepControl]="checksValid">
      <ng-template matStepLabel>Confirm</ng-template>
      Click the finalise button below to finalise the tow.
    </mat-step>
  </mat-horizontal-stepper>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-dialog-close mat-button style="margin-right: auto">Cancel</button>
  <button mat-stroked-button (click)="previousStep()" [disabled]="stepper.selectedIndex == 0">
    Previous
  </button>
  <button
    *ngIf="stepper.selectedIndex !== stepper.steps.length - 1"
    mat-flat-button
    [disabled]="!(checksValid | async) || finalising"
    (click)="nextStep()"
  >
    Next
  </button>
  <button
    *ngIf="stepper.selectedIndex == stepper.steps.length - 1"
    mat-flat-button
    [disabled]="!(checksValid | async) || finalising"
    (click)="finalise()"
  >
    Finalise
  </button>
</mat-dialog-actions>
