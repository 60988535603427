<app-header>
  @if (edit()) {
    <!--
        RANDOM ALLOC DISABLED PRIOR TO UAT 2024-10-30 

        @if (permission.has('allocate')) {
          <button mat-flat-button (click)="onAutoAllocate()">Random Allocation</button>
        }
        -->
    <button mat-stroked-button (click)="cancelEdit()">Cancel</button>
    <button mat-flat-button (click)="save()" [disabled]="!changesMade">Save</button>
  } @else {
    @if (permission.size && tow.metadata.status !== 'finalised') {
      <button mat-flat-button (click)="startEdit()">Edit</button>
    }
    <a mat-flat-button [routerLink]="['/tows', tow._id, 'samples', 'print']">Print</a>
  }
</app-header>

<mat-card>
  <mat-card-header>
    <mat-card-title>Details</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <b>Cut date: </b>
    <span>{{ tow.metadata.cut | date: 'd/M/y' }}</span>
    <a mat-button [routerLink]="['/tows', tow._id, 'edit']">Edit</a>
  </mat-card-content>
</mat-card>

<mat-card>
  <mat-card-header>
    <mat-card-title>Samples</mat-card-title>
  </mat-card-header>

  <mat-card-content>
    @if (edit()) {
      <button mat-button [matMenuTriggerFor]="selectionPresets">
        Selection Presets
        <mat-icon iconPositionEnd>chevron_right</mat-icon>
      </button>
      <mat-menu #selectionPresets="matMenu">
        <button mat-menu-item (click)="gridApi.selectAll()">All</button>
        <button mat-menu-item (click)="gridApi.deselectAll()">None</button>
        <button mat-menu-item (click)="setSelection('odds')">Odds</button>
        <button mat-menu-item (click)="setSelection('evens')">Evens</button>
        <button mat-menu-item (click)="setSelection('4ths')">Every 4th</button>
      </mat-menu>
    }

    @if (!loadingData && columnDefs && analysts) {
      <ag-grid-angular
        class="ag-theme-material"
        [class.editable]="edit()"
        [class.readonly]="!edit()"
        domLayout="autoHeight"
        [gridOptions]="gridOptions"
        [autoSizeStrategy]="autoSizeStrategy"
        [columnDefs]="columnDefs"
        [defaultColDef]="defaultColDef"
        [rowData]="rowData"
        [rowSelection]="rowSelection()"
        (cellEditingStopped)="onCellEditingStopped($event)"
        (gridReady)="onGridReady($event)"
      />
    }
  </mat-card-content>
</mat-card>
