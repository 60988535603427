import { Pipe, PipeTransform } from '@angular/core';
import { TowLogEventLabels, TTowLogEventCode } from '@cpr/shared/tow-log';

@Pipe({
  name: 'towLogEventCode',
  standalone: true,
})
export class TowLogEventCodePipe implements PipeTransform {
  transform(value: TTowLogEventCode): string {
    return TowLogEventLabels[value];
  }
}
