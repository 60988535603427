import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { FiltersModule } from '@suvo-bi-lib';
import { AgGridModule } from 'ag-grid-angular';
import { ReferencesModule } from 'submodules/frontend-framework/SUVO-BI-CLIENT-LIB/projects/suvo-bi-client-lib/src/lib/features/references/references.module';
import { TablesModule } from 'submodules/frontend-framework/SUVO-BI-CLIENT-LIB/projects/suvo-bi-client-lib/src/lib/features/tables/tables.module';
import { HeaderComponent } from '../../../shared/components/header/header.component';
import { SharedModule } from '../../../shared/shared.module';
import { ResponseInterceptor } from '../../interceptors/response.interceptor';
import { MapService } from '../maps/map.service';
import { PermissionsModule } from '../permissions/permissions.module';
import { SamplesModule } from '../samples/samples.module';
import { TaxonModule } from '../taxon/taxon.module';
import { TowLogMapComponent } from '../tow-log/components/map/tow-log-map.component';
import { TowLogModule } from '../tow-log/tow-log.module';
import { UsersModule } from '../users/users.module';
import { TowInfoTileComponent } from './components/info/tow-info-tile.component';
import { TowsMapComponent } from './components/map/tows-map.component';
import { TowLogDataTableComponent } from './components/tow-log-data-table/tow-log-data-table.component';
import { TowLogDataTableActionsRendererComponent } from './components/tow-log-data-table/tow-log-data-table-actions-renderer.component';
import { TowsTableComponent } from './components/table/tows-table.component';
import { FaultCodePickerComponent } from './fault-codes/fault-code-picker.component';
import { TowResolver } from './resolver/tow.resolver';
import { TowCuttingPointsScreenComponent } from './screens/cutting-points/tow-cutting-points-screen.component';
import { PciColourRendererComponent } from './screens/sample-allocation/pci-colour-renderer.component';
import { TowSampleAllocationScreenComponent } from './screens/sample-allocation/tow-sample-allocation-screen.component';
import { TowListScreenComponent } from './screens/list/tow-list-screen.component';
import { TowEditScreenComponent } from './screens/edit/tow-edit-screen.component';
import { TowIdFormControlComponent } from './screens/edit/tow-id-form-control.component';
import { TowLogScreenComponent } from './screens/tow-log/tow-log-screen.component';
import { TowLogTileComponent } from './screens/tow-overview/components/tow-log/tow-log-tile.component';
import { TowSamplesTileComponent } from './screens/tow-overview/components/samples/tow-samples-tile.component';
import { TowOverviewScreenComponent } from './screens/tow-overview/tow-overview-screen.component';
import { CuttingPointService } from './service/cutting-point.service';
import { TowsService } from './service/tows.service';

@NgModule({
  declarations: [
    // General components
    TowLogDataTableComponent,
    TowLogDataTableActionsRendererComponent,
    TowsTableComponent,
    PciColourRendererComponent,
    TowsMapComponent,

    // Tow overview screen tiles
    TowLogTileComponent,
    TowSamplesTileComponent,

    // Screens
    TowEditScreenComponent,
    TowCuttingPointsScreenComponent,
    TowLogScreenComponent,
    TowOverviewScreenComponent,
    TowSampleAllocationScreenComponent,
    TowListScreenComponent,
  ],
  providers: [
    CuttingPointService,
    MapService,
    TowsService,
    TowResolver,
    { provide: HTTP_INTERCEPTORS, useClass: ResponseInterceptor, multi: true },
  ],
  imports: [
    // Angular
    CommonModule,

    // 3rd party
    AgGridModule,
    LeafletModule,

    // SUVO lib
    FiltersModule,
    ReferencesModule,
    TablesModule,

    // Project shared
    SharedModule,

    // Project module
    PermissionsModule,
    SamplesModule,
    TaxonModule,
    TowLogModule,
    UsersModule,

    // Project component
    FaultCodePickerComponent,
    HeaderComponent,
    TowIdFormControlComponent,
    TowInfoTileComponent,
    TowLogMapComponent,
  ],
})
export class TowsModule {}
