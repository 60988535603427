import { AsyncPipe } from '@angular/common';
import { Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { RouterModule } from '@angular/router';
import { CHECK_BLOCK_RESULT_CODES, TCheckBlockResultCode } from '@cpr/shared/checkblocks';
import { SharedModule } from 'apps/mba-cpr-survey-portal/src/app/shared/shared.module';
import { PermissionsService } from '../../../permissions/permissions.service';
import { CheckBlockService } from '../../check-block.service';
import { ICheckBlock } from '../../interface/check-block.interface';
import { UsersService } from '../../../users/service/users.service';
import { UsersModule } from '../../../users/users.module';

@Component({
  standalone: true,
  imports: [
    AsyncPipe,
    SharedModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    RouterModule,
    UsersModule,
  ],
  selector: 'app-check-block-menu',
  template: `
    <mat-menu #menu="matMenu">
      @if (checkBlock) {
        <a mat-menu-item [routerLink]="['/checkblock', checkBlock._id, 'print']">Print</a>
        @if ((isSenior | async) && !checkBlock.resolved && !checkBlock.result) {
          <button mat-menu-item [matMenuTriggerFor]="result">Close & Action</button>
          <button mat-menu-item style="color: indianred" (click)="onDelete()">Cancel</button>
        }
      }
    </mat-menu>

    <mat-menu #result="matMenu">
      @for (code of resultCodes; track $index) {
        <button mat-menu-item (click)="onResolve(code)">{{ code }}</button>
      }
    </mat-menu>

    <button mat-icon-button [matMenuTriggerFor]="menu">
      <mat-icon>more_vert</mat-icon>
    </button>
  `,
})
export class CheckBlockMenuComponent {
  @Input() checkBlock: ICheckBlock;

  @Output() deleted = new EventEmitter<void>();
  @Output() resolved = new EventEmitter<TCheckBlockResultCode>();

  private readonly checkBlockService = inject(CheckBlockService);

  readonly isSenior = inject(PermissionsService).hasPermission('checkblocks', 'create');
  readonly resultCodes = CHECK_BLOCK_RESULT_CODES;

  onDelete() {
    this.checkBlockService.delete(this.checkBlock._id, () => this.deleted.emit());
  }

  onResolve(code: TCheckBlockResultCode) {
    this.checkBlockService.closeCheckBlock(this.checkBlock._id, code, () =>
      this.resolved.emit(code),
    );
  }
}
