import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { getAbundance } from '@cpr/shared/abundance';
import { ISample } from '../../samples/interface/sample.interface';
import { ITaxon } from '../../taxon/taxon.interface';
import { IMeasurement } from '../measurement.interface';
import { MeasurementActionMenuComponent } from './measurement-action-menu.component';

@Component({
  standalone: true,
  imports: [MeasurementActionMenuComponent, MatBadgeModule, MatButtonModule, MatIconModule],
  selector: 'app-measurement-list-item',
  template: `
    <div style="padding: 4px">
      <div style="display: flex; align-items: center; gap: 16px">
        <div style="flex: 1">
          <span>{{ taxon.taxonName }}</span>
          @if (bracketText) {
            <span style="color: #88f"> ({{ bracketText }})</span>
          }
          <span style="opacity: 50%"> (ID: {{ taxon.cprTaxonId }})</span>
        </div>
        @if (category) {
          <span style="opacity: 50%">(C{{ category }})</span>
        }
        <span style="min-width: 40px; text-align: right">{{ measurement.analysedCount }}</span>

        <app-measurement-action-menu
          [measurement]="measurement"
          (delete)="delete.emit()"
          (update)="measurement = $event; this.update.emit($event)"
        />
      </div>
      @if (measurement.comment) {
        <i style="opacity: 50%; padding-left: 16px">{{ measurement.comment }}</i>
      }
    </div>
  `,
})
export class MeasurementListItemComponent implements OnChanges {
  @Input() measurement: IMeasurement;
  @Input() sample: ISample;
  @Input() taxon: ITaxon;

  @Input() bracketText?: string;

  @Output() delete = new EventEmitter<void>();
  @Output() update = new EventEmitter<IMeasurement>();

  category?: number;

  ngOnChanges() {
    try {
      this.category = getAbundance(
        this.measurement.analysedCount,
        this.taxon.countingMethod,
        this.sample.factor,
      );
    } catch (e) {
      this.category = undefined;
    }
  }
}
