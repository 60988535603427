@if (mode === 'simple') {
  <mat-card>
    <mat-card-header>
      <mat-card-title>Tow log</mat-card-title>
      <a mat-button routerLink="log">View all</a>
    </mat-card-header>

    <mat-card-content>
      <div class="tow-log-summary">
        {{ towLog?.length }} tow log event{{ towLog?.length != 1 ? 's' : '' }}
      </div>
      <br />
      <div class="buttons">
        <button
          mat-flat-button
          color="primary"
          [disabled]="generateBtn.disabled"
          [matTooltip]="generateBtn.reason"
          (click)="onGenerate()"
        >
          Generate cutting points and samples
        </button>
      </div>
    </mat-card-content>
  </mat-card>
} @else if (mode === 'full') {
  <app-tow-log-data-table
    [cutsGenerated]="cutsGenerated"
    (towLogPushed)="onTowLogPush()"
    (editModeState)="onEditModeChange($event)"
  />
}
