import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FiltersModule, SharedModule as SharedLibModule, TablesModule } from '@suvo-bi-lib';
import { HeaderComponent } from '../../../shared/components/header/header.component';
import { SharedModule } from '../../../shared/shared.module';
import { PermissionsModule } from '../permissions/permissions.module';
import { UserAvatarComponent } from './components/avatar/user-avatar.component';
import { UsersTableComponent } from './components/table/users-table.component';
import { UserListScreenComponent } from './screens/user-list-screen/user-list-screen.component';
import { UserProfileScreenComponent } from './screens/user-profile-screen/user-profile-screen.component';
import { UsersService } from './service/users.service';

@NgModule({
  imports: [
    CommonModule,
    HeaderComponent,
    PermissionsModule,
    SharedModule,
    SharedLibModule,
    TablesModule,
    FiltersModule
  ],
  providers: [UsersService],
  declarations: [
    UsersTableComponent,
    UserListScreenComponent,
    UserProfileScreenComponent,
    UserAvatarComponent,
  ],
  exports: [UserAvatarComponent],
})
export class UsersModule {}
