import { AsyncPipe } from '@angular/common';
import { Component, computed, inject, input, output } from '@angular/core';
import { MatBadge } from '@angular/material/badge';
import { UsersService } from '../../../../users/service/users.service';
import { Cell } from '../interfaces/cell.interface';

@Component({
  standalone: true,
  imports: [AsyncPipe, MatBadge],
  selector: 'app-analysis-overview-cell-clickable',
  template: `
    <div
      [class.active]="active()"
      (click)="click.emit($event)"
      [matBadge]="badgeContent()"
      [style.--mat-badge-background-color]="badgeBgColour() | async"
      [style.--mat-badge-text-color]="badgeTxtColour() | async"
    >
      {{ cell()?.abundance }}
    </div>
  `,
  styles: `
    div {
      height: 48px;
      width: 48px;

      display: flex;
      justify-content: center;
      align-items: center;

      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      background: #fff;

      border-bottom: 1px solid var(--border-color);
      border-right: 1px solid var(--border-color);

      cursor: pointer;
    }

    .active {
      background: rgba(206, 232, 229, 0.3);
    }

    .mat-badge-content {
      left: calc(100% - 6px);
      bottom: calc(100% - 6px);
    }
  `,
})
export class AnalysisOverviewClickableCellComponent {
  private readonly usersService = inject(UsersService);

  // Inputs

  readonly cell = input<Cell>(null);
  readonly active = input<boolean>();

  // Outputs

  readonly click = output<PointerEvent>();

  // Computed States

  readonly cbResolved = computed(
    () => this.cell()?.checkBlock?.resolved || this.cell()?.checkBlock?.result,
  );

  readonly badgeContent = computed(() => {
    if (this.cell()?.checkBlock) {
      return this.cbResolved() ? '✓' : '!';
    }
    // else return void (no content)
  });

  // Yellow : Raised, senior last message
  // Blue   : assigned analyst last message
  // Red    : Unassigned
  // Green  : resolved
  readonly badgeBgColour = computed(() => {
    const cb = this.cell().checkBlock;
    const resolved = this.cbResolved();
    return (async () => {
      if (cb) {
        if (!cb.userId) {
          return 'indianred';
        }
        if (resolved) {
          return 'var(--primary-color)';
        }
        if (cb.messages.length == 1) {
          return '#ff0';
        }
        const lastMessageUID = cb.messages.at(-1).userId;
        const analysts = await this.usersService.analysts;
        const lastMessageWasSenior = !!analysts.find((u) => u._id === lastMessageUID);
        return lastMessageWasSenior ? '#ff0' : '#00f';
      }
    })();
  });

  // Make badge txt black if bg is yellow
  readonly badgeTxtColour = computed(() =>
    this.badgeBgColour().then((bgColour) => (bgColour === '#ff0' ? '#000' : '#fff')),
  );
}
