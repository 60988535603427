import { IdAssigned, Timestamped } from '../../document.interfaces';

/** An immutable array of available tow log event codes. */
export const TowLogEventCodes = ['SHOOT', 'MIDHAUL', 'COURSE_CHANGE', 'MIDSHOOT', 'HAUL'] as const;

/** Immutable map of tow log event codes to friendly labels. */
export const TowLogEventLabels = {
  SHOOT: 'Shoot',
  HAUL: 'Haul',
  COURSE_CHANGE: 'Altercourse',
  MIDHAUL: 'Mid haul',
  MIDSHOOT: 'Mid shoot',
} as const;

/** Union type for a tow log event code. */
export type TTowLogEventCode = (typeof TowLogEventCodes)[number];

/**
 * Represents a single event of a tow log.
 *
 * @template T The type for object IDs
 */
export interface ITowLogEvent<TObjectId, TDate extends Date | string> {
  towId: TObjectId;
  eventNo?: number;
  eventCode: TTowLogEventCode;
  eventLatitude: number;
  eventLongitude: number;
  eventDate: TDate;
  silkReading?: number;
  comments?: string;
}

export type ITowLogEventHydrated<TObjectId, TDate extends Date | string> = ITowLogEvent<
  TObjectId,
  TDate
> &
  IdAssigned<TObjectId> &
  Timestamped;

export interface CSD {
  course: number;
  speed: number;
  distance: number;
}

export interface ITowLeg<T extends ITowLogEvent<unknown, Date | string>> extends CSD {
  start: T;
  end: T;
}
