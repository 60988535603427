import { Component, computed, effect, input, OnDestroy, ViewChild } from '@angular/core';
import { MatExpansionModule, MatExpansionPanel } from '@angular/material/expansion';
import { ABUNDANCE_CATEGORIES } from '@cpr/shared/abundance/abundance-category';
import { COUNTING_METHODS, TCountingMethodType } from '@cpr/shared/counting-method';

@Component({
  standalone: true,
  imports: [MatExpansionModule],
  selector: 'app-abundance-categories',
  template: `
    <mat-expansion-panel
      #expansionPanel
      [expanded]="false"
      style="margin-bottom: var(--global-padding); background: var(--primary-lighter-color)"
      [disabled]="countingMethod() === 'PHYTO'"
    >
      <mat-expansion-panel-header>
        Abundance Categories
        @if (countingMethod() === 'PHYTO') {
          (unavailable for phyto)
        }
      </mat-expansion-panel-header>
      <table style="text-align: center">
        <thead>
          <tr>
            <th>Category</th>
            <th>Min</th>
            <th>Max</th>
            <th>Mean</th>
            <th>Mean Per Sample</th>
          </tr>
        </thead>
        <tbody>
          @for (category of categories(); track $index) {
            <tr>
              <td>{{ category[1] }}</td>
              <td>{{ category[3] }}</td>
              <td>{{ category[4] }}</td>
              <td>{{ category[5] }}</td>
              <td>{{ category[6] }}</td>
            </tr>
          }
        </tbody>
      </table>
    </mat-expansion-panel>
  `,
})
export class AbundanceCategoriesComponent implements OnDestroy {
  @ViewChild('expansionPanel') panel: MatExpansionPanel;

  readonly countingMethod = input<TCountingMethodType>();

  readonly categories = computed(() =>
    ABUNDANCE_CATEGORIES.filter(
      ([, , method]) => method === COUNTING_METHODS[this.countingMethod()],
    ).sort((a, b) => a[1] - b[1]),
  );

  private readonly onCountingMethodChange = effect(() => {
    if (this.countingMethod() === 'PHYTO') {
      this.panel?.close();
    }
  });

  ngOnDestroy() {
    this.onCountingMethodChange.destroy();
  }
}
