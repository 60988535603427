<h2 mat-dialog-title>Finalise Sample</h2>

<mat-dialog-content>
  @if (!(checksValid | async)) {
    <p style="color: indianred">Cannot finalise due to errors - see below.</p>
  }

  @if (checks | async; as checks) {
    <app-finalisation-report [report]="checks" />
  } @else {
    <mat-spinner />
  }
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-stroked-button mat-dialog-close (click)="cancel()">Cancel</button>
  <button
    mat-flat-button
    mat-dialog-close
    [disabled]="!(checksValid | async) || finalising"
    (click)="finalise()"
  >
    Finalise
  </button>
</mat-dialog-actions>
