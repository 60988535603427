import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { ISample } from '../../interface/sample.interface';

@Component({
  selector: 'app-sample-data-entry-sample-switcher',
  template: `
    <button mat-button [matMenuTriggerFor]="menu">Switch Sample</button>
    <mat-menu #menu="matMenu">
      @for (sample of samples; track $index) {
        @if (sample._id !== currentSampleID) {
          <!-- Changed to href instead of routerLink because the screen is not reactive -->
          <a
            mat-menu-item
            [href]="'tows/' + sample.towId + '/samples/' + sample._id"
            [style.opacity]="sample.finalisedAt ? '50%' : '100%'"
          >
            <div style="display: flex; align-items: center; gap: 16px">
              <app-user-avatar [userId]="sample.userId" [showLabel]="false" />
              <span>{{ sample.sampleNo }}</span>
            </div>
          </a>
        }
      }
    </mat-menu>
  `,
})
export class DataEntrySampleSwitcherComponent {
  private readonly unsubscribe$ = new Subject<void>();

  currentSampleID: string;

  samples: ISample[];

  constructor(activatedRoute: ActivatedRoute) {
    activatedRoute.data.pipe(takeUntil(this.unsubscribe$)).subscribe(({ samples }) => {
      this.samples = samples;
    });
    activatedRoute.params.pipe(takeUntil(this.unsubscribe$)).subscribe(({ sampleID }) => {
      this.currentSampleID = sampleID;
    });
  }
}
