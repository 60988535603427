import { AsyncPipe, CommonModule, KeyValuePipe } from '@angular/common';
import { Component, inject, ViewChild } from '@angular/core';
import { MatButton } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatStepper, MatStepperModule } from '@angular/material/stepper';
import { FinalisationCheckResult } from '@cpr/shared/finalisation';
import { SharedModule } from '@suvo-bi-lib';
import { take } from 'rxjs';
import { ConfirmationDialogComponent } from 'submodules/frontend-framework/SUVO-BI-CLIENT-LIB/projects/suvo-bi-client-lib/src/lib/shared/confirmation-dialog/confirmation.component';
import { TowLogPrintoutComponent } from '../../../printout/tow-log-printout.component';
import { SamplesService } from '../../../samples/service/samples.service';
import { ITow } from '../../../tows/interface/tow.interface';
import { TowsService } from '../../../tows/service/tows.service';
import { TowsModule } from '../../../tows/tows.module';
import { TowLogApiService } from '../../../tow-log/services/tow-log-api.service';
import { TowLogModule } from '../../../tow-log/tow-log.module';
import { ITowLogEventPaginated } from '../../../tows/interface/tow-log-event.interface';
import { FinalisationReport } from '../finalisation-report/finalisation-report.component';

export interface FinaliseTowDialogData {
  towId: string;
  onConfirm: () => void;
}

@Component({
  standalone: true,
  imports: [
    CommonModule,
    AsyncPipe,
    FinalisationReport,
    KeyValuePipe,
    MatButton,
    MatDialogModule,
    MatIcon,
    MatProgressSpinner,
    MatStepperModule,
    TowLogPrintoutComponent,
    TowLogModule,
    TowsModule,
    SharedModule,
  ],
  templateUrl: './finalise-tow-dialog.component.html',
  styles: `
    ::ng-deep .mat-horizontal-stepper-header-container {
      pointer-events: none !important;
    }
  `,
})
export class FinaliseTowDialogComponent {
  @ViewChild('stepper') stepper: MatStepper;
  private readonly dialogData = inject<FinaliseTowDialogData>(MAT_DIALOG_DATA);
  private readonly matDialogRef = inject(MatDialogRef<FinaliseTowDialogComponent>);
  private readonly sampleService = inject(SamplesService);
  private readonly towService = inject(TowsService);
  private readonly towLogService = inject(TowLogApiService);
  private readonly matDialog = inject(MatDialog);

  protected tow: ITow;
  protected towLog: ITowLogEventPaginated;

  async ngOnInit() {
    this.tow = (await this.towService.get(this.dialogData.towId)) as ITow;
    this.towLog = await this.towLogService.getPaginated('', {
      simpleFilters: { towId: `ObjectId:${this.dialogData.towId}` },
    });
  }

  readonly checks: Promise<Record<number, FinalisationCheckResult[]>> = this.sampleService
    .checkTow(this.dialogData.towId)
    .catch((e) => e.error.checks);

  readonly checksValid = this.checks.then((samples) =>
    Object.values(samples).every((sample) => sample.every((check) => check[0] !== 'ERR')),
  );

  finalising = false;

  finalise() {
    const confirmationDialog = this.matDialog.open(ConfirmationDialogComponent, {
      data: {
        confirmationText: 'Are you sure you want to finalise this tow?',
      },
    });
    confirmationDialog
      .afterClosed()
      .pipe(take(1))
      .subscribe((data) => {
        if (data.confirmed) {
          this.finalising = true;
          this.sampleService
            .finaliseTow(this.dialogData.towId)
            .then(() => this.dialogData.onConfirm())
            .finally(() => (this.finalising = false));

          this.matDialogRef.close();
        }
      });
  }

  previousStep() {
    if (this.stepper.selectedIndex >= 1) {
      this.stepper.selectedIndex--;
    }
  }

  nextStep() {
    this.stepper.selectedIndex++;
  }
}
