import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatSidenav } from '@angular/material/sidenav';
import { getAbundance } from '@cpr/shared/abundance';
import { TCountingMethodType } from '@cpr/shared/counting-method';
import { isAtNight } from '@cpr/shared/sun-times';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ITaxon } from '../../../taxon/taxon.interface';
import { TaxonService } from '../../../taxon/taxon.service';
import { IRecordingEvent } from '../../../tows/interface/recording-event.interface';
import { TowsService } from '../../../tows/service/tows.service';
import { UsersService } from '../../../users/service/users.service';
import { FinaliseTowDialogComponent } from '../../../finalisation/components/finalise-tow-dialog/finalise-tow-dialog.component';
import { IAnalysisSample } from '../../interface/analysis-sample.interface';
import { Cell } from './interfaces/cell.interface';

type GridSection = {
  name: string;
  values: Array<{
    taxon: ITaxon;
    values: Cell[];
  }>;
};

@Component({
  templateUrl: './sample-analysis-overview-screen.component.html',
  styleUrls: ['./sample-analysis-overview-screen.component.scss'],
})
export class SampleAnalysisScreenComponent implements OnInit, OnDestroy {
  private readonly unsubscribe$ = new Subject<void>();

  @ViewChild('panel') panel: MatSidenav;

  tow: IRecordingEvent<'tow'>;
  samples: IAnalysisSample[];

  private mappedGridData: Record<string, Cell>;
  orderedGridData: GridSection[];

  viewing: Cell;

  loading = true;

  expandLabels = false;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly dialog: MatDialog,
    private readonly taxonService: TaxonService,
    private readonly towsService: TowsService,
    public readonly users: UsersService,
  ) {}

  ngOnInit() {
    this.route.data.pipe(takeUntil(this.unsubscribe$)).subscribe(({ data }) => {
      this.tow = data;
      this.loadMeasurements();
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  /** Uses a map to lookup a specific value quickly. */
  getGridValue(sampleId: string, taxonId: string) {
    return this.mappedGridData[sampleId + taxonId];
  }

  async loadMeasurements() {
    this.loading = true;

    // Returns samples populated with check blocks and measurements
    const towSamplesRequest = this.towsService.getAnalysis(this.tow._id).then((samples) =>
      samples
        // Filter to assigned samples only
        .filter((sample) => !!sample.userId && sample.user?.legacyAnalystId != 99)
        // Sort by sample number
        .sort((a, b) => a.sampleNo - b.sampleNo),
    );

    const [towSamples, allTaxons] = await Promise.all([
      towSamplesRequest,
      this.taxonService.getFlat(),
    ]);

    this.samples = towSamples;

    this.mappedGridData = {};

    const countingMethodMap: { [K in TCountingMethodType]: GridSection['values'] } = {
      PHYTO: [],
      TRAVERSE: [],
      EYECOUNT: [],
    };

    this.orderedGridData = [
      { name: 'Phyto', values: countingMethodMap.PHYTO },
      { name: 'Traverse', values: countingMethodMap.TRAVERSE },
      { name: 'Eyecount', values: countingMethodMap.EYECOUNT },
    ];

    for (const taxon of allTaxons) {
      // First pass, check if we should bother structuring data for this taxon, otherwise skip it
      // this should reduce gc time
      for (const sample of towSamples) {
        if (
          sample.checkBlocks.find((cb) => cb.taxonId === taxon._id) ||
          sample.measurements.find((m) => m.taxonId === taxon._id)
        ) {
          // The taxon is present in the tow
          // Start a second pass to generate a structure for each item
          const values = [];

          for (const sample of towSamples) {
            const checkBlock = sample.checkBlocks.find((cb) => cb.taxonId === taxon._id);
            const measurement = sample.measurements.find((m) => m.taxonId === taxon._id);

            let abundance: number | '+';

            if (measurement?.analysedCount)
              try {
                abundance =
                  measurement.analysedCount === '+'
                    ? '+'
                    : getAbundance(measurement.analysedCount, taxon.countingMethod, sample.factor);
              } catch (e) {}

            // We populate both the mapped and ordered data structures
            values.push(
              (this.mappedGridData[sample._id + taxon._id] = {
                abundance,
                checkBlock,
                measurement,
                sample,
                taxon,
              }),
            );
          }

          countingMethodMap[taxon.countingMethod].push({ taxon, values });
          break;
        }
      }
    }

    this.loading = false;
  }

  finaliseTow() {
    this.dialog.open(FinaliseTowDialogComponent, {
      data: {
        towId: this.tow._id,
        onConfirm: () => {
          this.tow.metadata.status = 'finalised';
        },
      },
      minWidth: '800px',
    });
  }

  getDayOrNight(sample: IAnalysisSample) {
    return isAtNight(sample.sampleMidpointDate, sample.sampleLongitude, sample.sampleLatitude)
      ? 'N'
      : 'D';
  }
}
