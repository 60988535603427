import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { environment } from '$env';
import type * as T from '../interfaces/vessel-track';

const pad = (n: number) => n.toString().padStart(2, '0');

@Injectable({ providedIn: 'root' })
export class MarineTrafficApiService {
  private readonly http = inject(HttpClient);

  vesselTrack(options: T.ExportVesselTrackOptions) {
    return lastValueFrom(
      this.http.post<T.ExportVesselTrackResult>(
        `${environment.cprServiceApi}marine-traffic/vessel-track`,
        options,
      ),
    );
  }

  formatDate(date: number | string | Date) {
    date = new Date(date);

    const YYYY = date.getUTCFullYear();
    const MM = pad(date.getUTCMonth() + 1);
    const DD = pad(date.getUTCDate());
    const HH = pad(date.getUTCHours());
    const mm = pad(date.getUTCMinutes());
    const ss = pad(date.getUTCSeconds());

    return `${YYYY}-${MM}-${DD} ${HH}:${mm}:${ss}`;
  }
}
