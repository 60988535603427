import { Component, input } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { FinalisationCheckResult } from '@cpr/shared/finalisation';

@Component({
  standalone: true,
  imports: [MatIcon],
  selector: 'app-finalisation-report',
  template: `
    @for (check of report(); track $index) {
      <div style="display: flex; align-items: center; gap: 12px; padding: 6px 0">
        @switch (check[0]) {
          @case ('ERR') {
            <mat-icon style="color: #cd5c5c">error</mat-icon>
          }
          @case ('WARN') {
            <mat-icon style="color: #ffd900">error</mat-icon>
          }
          @case ('OK') {
            <mat-icon style="color: var(--primary-color)">check_circle</mat-icon>
          }
        }
        <span>{{ check[1] }}</span>
      </div>
    }
  `,
  styles: `
    mat-icon {
      min-width: 24px;
    }
  `,
})
export class FinalisationReport {
  readonly report = input<FinalisationCheckResult[]>();
}
