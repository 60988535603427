import { AsyncPipe, DatePipe } from '@angular/common';
import { Component, computed, inject, input } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { SharedModule } from '../../../../../shared/shared.module';
import { PermissionsService } from '../../../permissions/permissions.service';
import { IRecordingEvent } from '../../interface/recording-event.interface';
import { TowsService } from '../../service/tows.service';

@Component({
  standalone: true,
  imports: [AsyncPipe, DatePipe, MatButton, MatCardModule, SharedModule],
  selector: 'app-tow-info-tile',
  template: `
    <mat-card>
      <mat-card-header class="between">
        <mat-card-title>Key Information</mat-card-title>

        @if (canEdit() | async) {
          <button mat-flat-button routerLink="edit">Edit</button>
        } @else {
          <button mat-button routerLink="edit">View Details</button>
        }
      </mat-card-header>

      <mat-card-content>
        @if (tow()?.metadata; as md) {
          <div class="col">
            <div>
              <span>Tow ID:</span>
              <span>{{ md.oldTowBaseId }}</span>
            </div>

            <div>
              <span>CPR:</span>
              @if (md.cpr; as cpr) {
                <a [routerLink]="['/cprs', cpr._id]">{{ cpr.oldCprId }}</a>
              }
            </div>

            <div>
              <span>Internal:</span>
              @if (md.internalId; as internal) {
                <span>{{ internal }}</span>
              }
            </div>

            <div>
              <span>Ship:</span>
              @if (md.ship; as ship) {
                <a [routerLink]="['/ships', ship._id]">{{ ship.name }}</a>
              }
            </div>
          </div>

          <div class="col">
            <div>
              <span>Date:</span>
              <span>
                @if (md.month) {
                  {{ towService.toDate(tow()) | date: 'MMM' : 'UTC' }}
                }
                @if (md.year) {
                  {{ towService.toDate(tow()) | date: 'yyyy' : 'UTC' }}
                }
              </span>
            </div>

            <div>
              <span>Status:</span>
              @if (md.status; as status) {
                <span>
                  <app-status-chip [value]="isFinalised() ? 'finalised' : status" />
                </span>
              }
            </div>

            <div>
              <span>Cut Date:</span>
              @if (md.cut; as cut) {
                <span>{{ cut | date: 'y-M-d' }}</span>
              }
            </div>

            <div>
              <span>Validity:</span>
              @if (md.validity; as validity) {
                <span>{{ validity }}</span>
              }
            </div>
          </div>

          <div class="kp">
            <span>Comments:</span>
            @if (md.message; as message) {
              <span>{{ message }}</span>
            }
          </div>
        }
      </mat-card-content>
    </mat-card>
  `,
  styles: `
    .col {
      display: inline-block;
      vertical-align: top;
      margin-left: 96px;
    }

    .col:first-child {
      margin-left: 0;
    }

    .kp,
    .col > * {
      display: flex;
      align-items: center;
      min-height: 48px;
    }

    .kp > *:first-child,
    .col > * > *:first-child {
      min-width: 96px;
      font-weight: 600;
    }
  `,
})
export class TowInfoTileComponent {
  readonly towService = inject(TowsService);
  readonly hasPermission = inject(PermissionsService).hasPermission('recordingEvent', 'edit');

  readonly tow = input<IRecordingEvent<'tow'>>();

  readonly isFinalised = computed(() => this.tow().metadata?.status === 'finalised');
  readonly canEdit = computed(async () => !this.isFinalised() && (await this.hasPermission));
}
