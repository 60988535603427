// Web Console version had this comment:

// This code appears to have been taken from source currently forked at
// https://github.com/ldmbouge/vstlf/tree/master/src/com/web_tomorrow/utils/suntimes
// I have no idea where the original source is
// The version in old Console used Year, Month, Day and System.Time, throughout
// We only have DateTime and TimeSpan available

// This is now a JavaScript fork of the C# fork of the Java fork :)

/** Default value for Sun's zenith and true rise/set. */
export const ZENITH = 90 + 50.0 / 60.0;

/** Sun's zenith at civil twilight. */
export const CIVIL_ZENITH = 96;

/** Sun's zenith at nautical twilight. */
export const NAUTICAL_ZENITH = 102;

/** Sun's zenith at astronomical twilight. */
export const ASTRONOMICAL_ZENITH = 108;

export const SUNRISE = 6.0;
export const SUNSET = 18.0;
export type SunTime = typeof SUNRISE | typeof SUNSET;

/** Number of degrees of longitude that corresponds to one hour time difference. */
const DEG_PER_HOUR = 360.0 / 24.0;

/** Sin of an angle in degrees. */
const sinDeg = (deg: number) => Math.sin((deg * 2.0 * Math.PI) / 360.0);

/** Acos of an angle, result in degrees. */
const acosDeg = (x: number) => (Math.acos(x) * 360.0) / (2 * Math.PI);

/** Asin of an angle, result in degrees. */
const asinDeg = (x: number) => (Math.asin(x) * 360.0) / (2 * Math.PI);

/** Tan of an angle in degrees. */
const tanDeg = (deg: number) => Math.tan((deg * 2.0 * Math.PI) / 360.0);

/** Cos of an angle in degrees. */
const cosDeg = (deg: number) => Math.cos((deg * 2.0 * Math.PI) / 360.0);

/**
 * Calculate the day of the year, where Jan 1st is day 1. Note that this method needs to know the
 * year, because leap years have an impact here.
 */
function getDayOfYear(year: number, month: number, day: number) {
  const N1 = (275 * month) / 9;
  const N2 = (month + 9) / 12;
  const N3 = 1 + (year - 4 * (year / 4) + 2) / 3;
  const N = N1 - N2 * N3 + day - 30;
  return N;
}

/**
 * Get time difference between location's longitude and the Meridian, in hours. West of Meridian has
 * a negative time difference.
 */
const getHoursFromMeridian = (lng: number) => lng / DEG_PER_HOUR;

/**
 * Gets the approximate time of sunset or sunrise In days since midnight Jan 1st, assuming 6am and
 * 6pm events. We need this figure to derive the Sun's mean anomaly.
 */
function getApproxTimeDays(dayOfYear: number, hrsFromMeridian: number, type: SunTime) {
  return dayOfYear + (type - hrsFromMeridian) / 24;
}

/**
 * Calculate the Sun's mean anomaly in degrees, at sunrise or sunset, given the longitude in
 * degrees.
 */
function getMeanAnomaly(dayOfYear: number, lng: number, type: SunTime) {
  return 0.9856 * getApproxTimeDays(dayOfYear, getHoursFromMeridian(lng), type) - 3.289;
}

/**
 * Calculates the Sun's true longitude in degrees. The result is an angle gte 0 and lt 360. Requires
 * the Sun's mean anomaly, also in degrees
 */
function getSunTrueLongitude(sunMeanAnomaly: number) {
  let l =
    sunMeanAnomaly + 1.916 * sinDeg(sunMeanAnomaly) + 0.02 * sinDeg(2 * sunMeanAnomaly) + 282.634;

  // Get longitude into 0-360 degree range
  if (l >= 360.0) {
    l = l - 360.0;
  }
  if (l < 0) {
    l = l + 360.0;
  }

  return l;
}

/**
 * Calculates the Sun's right ascension in hours, given the Sun's true longitude in degrees. Input
 * and output are angles gte 0 and lt 360.
 */
function getSunRightAscensionHours(sunTrueLongitude: number) {
  const a = 0.91764 * tanDeg(sunTrueLongitude);
  let ra = (360.0 / (2.0 * Math.PI)) * Math.atan(a);

  //   // Get longitude into 0-360 degree range
  //   if (l >= 360.0) {
  //     l = l - 360.0;
  //   }
  //   if (l < 0) {
  //     l = l + 360.0;
  //   }

  const lQuadrant = Math.floor(sunTrueLongitude / 90.0) * 90.0;
  const raQuadrant = Math.floor(ra / 90.0) * 90.0;
  ra = ra + (lQuadrant - raQuadrant);

  return ra / DEG_PER_HOUR; // Convert to hours
}

/**
 * Get the cosine of the Sun's local hour angle.
 *
 * @param sunTrueLongitude
 * @param lat
 * @param zenith If omitted, the default zenith is used.
 */
function getCosLocalHourAngle(sunTrueLongitude: number, lat: number, zenith = ZENITH) {
  const sinDec = 0.39782 * sinDeg(sunTrueLongitude);
  const cosDec = cosDeg(asinDeg(sinDec));

  const cosH = (cosDeg(zenith) - sinDec * sinDeg(lat)) / (cosDec * cosDeg(lat));

  // Check bounds

  return cosH;
}

/**
 * Calculate local mean time of rising or setting. By `local' is meant the exact time at the
 * location, assuming that there were no time zone. That is, the time difference between the
 * location and the Meridian depended entirely on the longitude. We can't do anything with this time
 * directly; we must convert it to UTC and then to a locale time. The result is expressed as a
 * fractional number of hours since midnight.
 */
function getLocalMeanTime(
  localHour: number,
  sunRightAscensionHours: number,
  approxTimeDays: number,
) {
  return localHour + sunRightAscensionHours - 0.06571 * approxTimeDays - 6.622;
}

class AlwaysNightError extends Error {
  constructor() {
    super('Sun never rises on the specified date at the specified location');
  }
}

class AlwaysDayError extends Error {
  constructor() {
    super('Sun never sets on the specified date at the specified location');
  }
}

/**
 * Get sunrise or sunset time in UTC, according to flag.
 *
 * @param year 4-digit year.
 * @param month Month, 1-12.
 * @param day Day of month, 1-31.
 * @param lng In degrees, longitudes west of Meridian are negative.
 * @param lat In degrees, latitudes south of equator are negative.
 * @param zenith Sun's zenith, in degrees.
 * @param type Type of calculation to carry out.
 * @returns A fractional number of hours.
 */
function getTimeUTC(
  year: number,
  month: number,
  day: number,
  lng: number,
  lat: number,
  zenith: number,
  type: SunTime,
) {
  const dayOfYear = getDayOfYear(year, month, day);
  const sunMeanAnomaly = getMeanAnomaly(dayOfYear, lng, type);
  const sunTrueLng = getSunTrueLongitude(sunMeanAnomaly);
  const cosLocalHourAngle = getCosLocalHourAngle(sunTrueLng, lat, zenith);

  let localHourAngle: number;

  if (type == SUNRISE) {
    if (cosLocalHourAngle > 1) {
      throw new AlwaysNightError();
    }
    localHourAngle = 360.0 - acosDeg(cosLocalHourAngle);
  } else if (type == SUNSET) {
    if (cosLocalHourAngle < -1) {
      throw new AlwaysDayError();
    }
    localHourAngle = acosDeg(cosLocalHourAngle);
  } else {
    throw new TypeError('Invalid sun time');
  }

  const hrsFromMeridian = getHoursFromMeridian(lng);

  const localMeanTime = getLocalMeanTime(
    localHourAngle / DEG_PER_HOUR,
    getSunRightAscensionHours(sunTrueLng),
    getApproxTimeDays(dayOfYear, hrsFromMeridian, type),
  );

  return localMeanTime - hrsFromMeridian;
}

/** Given a UTC date, time, zenith, and position, determine whether it is Night or Day. */
export function isAtNight(
  eventDate: number | string | Date,
  lng: number,
  lat: number,
  zenith = ZENITH,
) {
  const date = new Date(eventDate);

  const year = date.getUTCFullYear();
  const month = date.getUTCMonth() + 1;
  const day = date.getUTCDate();

  try {
    var sunSet = getTimeUTC(year, month, day, lng, lat, zenith, SUNSET);
    var sunRise = getTimeUTC(year, month, day, lng, lat, zenith, SUNRISE);
  } catch (e) {
    if (e instanceof AlwaysDayError) {
      return false;
    } else if (e instanceof AlwaysNightError) {
      return true;
    } else {
      throw e;
    }
  }

  const time = date.getUTCHours() + date.getUTCMinutes() / 60 + date.getUTCSeconds() / 60 / 60;

  if (sunRise <= time && time < sunSet) {
    return false;
  } else if (sunRise >= sunSet && time < sunSet) {
    return false;
  } else if (sunRise >= sunSet && time >= sunRise) {
    return false;
  } else {
    return true;
  }
}

export const getLocalTime = (eventDate: number | string | Date, lng: number) =>
  new Date(new Date(eventDate).getTime() + getHoursFromMeridian(lng) * 36e5);
