import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { measurementCountFormControl } from '../form-controls/measurement-count-form-control';
import { IMeasurement } from '../measurement.interface';

export type IMeasurementUpdate = Pick<IMeasurement, 'analysedCount' | 'comment'>;

export interface MeasurementEditDialogData {
  measurement: IMeasurementUpdate;
  onConfirm: (update: IMeasurementUpdate) => void;
}

@Component({
  standalone: true,
  imports: [
    ReactiveFormsModule,
    MatButtonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
  ],
  selector: 'app-confirm-dialog',
  template: `
    <h1 mat-dialog-title>Edit Measurement</h1>
    <form mat-dialog-content style="padding-top: 18px" [formGroup]="form">
      <mat-form-field style="width: 100%">
        <mat-label>Analysed Count</mat-label>
        <input matInput formControlName="analysedCount" />
        @if (form.controls.analysedCount.invalid) {
          <mat-error>Please enter a positive integer or a '+'.</mat-error>
        }
      </mat-form-field>
      <mat-form-field style="width: 100%">
        <mat-label>Comment</mat-label>
        <textarea matInput formControlName="comment"></textarea>
      </mat-form-field>
    </form>
    <div mat-dialog-actions style="justify-content: flex-end">
      <button mat-button mat-dialog-close>Cancel</button>
      <button mat-flat-button color="primary" mat-dialog-close (click)="onConfirm()">OK</button>
    </div>
  `,
})
export class MeasurementEditDialog {
  readonly form = new FormGroup({
    analysedCount: measurementCountFormControl(),
    comment: new FormControl<string>(undefined),
  });

  constructor(@Inject(MAT_DIALOG_DATA) readonly data: MeasurementEditDialogData) {
    this.form.patchValue({
      analysedCount: data.measurement.analysedCount?.toString(),
      comment: data.measurement.comment,
    });
  }

  onConfirm() {
    let analysedCount: IMeasurement['analysedCount'];
    if (this.form.value.analysedCount === '+') {
      analysedCount = '+';
    } else if (typeof this.form.value.analysedCount === 'string') {
      analysedCount = parseInt(this.form.value.analysedCount);
    } else if (typeof this.form.value.analysedCount === 'number') {
      analysedCount = this.form.value.analysedCount;
    }
    this.data.onConfirm({
      analysedCount,
      comment: this.form.value.comment,
    });
  }
}
