import { Component, Input } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { ICheckBlock } from '../../interface/check-block.interface';
import { CheckBlockCardComponent } from '../card/check-block-card.component';
import { ICprUser } from '../../../users/interface/user.interface';
import { UsersService } from '../../../users/service/users.service';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormsModule } from '@angular/forms';
import {CommonModule} from '@angular/common';

@Component({
  standalone: true,
  imports: [CommonModule, MatCardModule, MatCheckboxModule, CheckBlockCardComponent, FormsModule],
  selector: 'app-check-blocks-tile',
  template: `
    <mat-card>
      <mat-card-header class="between">
        <mat-card-title>{{ title }}</mat-card-title>
        <ng-content select="[header]" />
      </mat-card-header>
      <mat-card-content>
        <div>
          <mat-checkbox
            (change)="onOnlyCheckblocksWaitingForMyResponseToggle($event)"
            [(ngModel)]="showOnlyThoseWaitingForMyComment"
            style="margin-right:30px"
          >
            Only show checkblocks waiting for your response
          </mat-checkbox>
          <mat-checkbox (change)="onShowResolvedToggle($event)" [(ngModel)]="showResolved" *ngIf="canShowResolved">
            Include resolved checkblocks
          </mat-checkbox>
        </div>
        <br />
        <div style="display: flex; flex-wrap: wrap; gap: 16px">
          @if (filteredCheckblocks?.length) {
            @for (checkBlock of filteredCheckblocks; track checkBlock._id) {
              <app-check-block-card [checkBlock]="checkBlock" />
            }
          } @else {
            <ng-content select="[whenNone]" />
          }
        </div>
      </mat-card-content>
    </mat-card>
  `,
})
export class CheckBlocksTileComponent {
  @Input() title: string = 'Your active checkblocks';
  @Input() tag: string;
  @Input() checkBlocks: ICheckBlock[];
  @Input() canShowResolved: boolean;

  showResolved: boolean = false;
  showOnlyThoseWaitingForMyComment: boolean = true;

  currentUser: ICprUser;

  filteredCheckblocks: ICheckBlock[];

  constructor(private userService: UsersService) {}

  async ngOnInit() {
    this.showOnlyThoseWaitingForMyComment =
      (await localStorage.getItem(this.tag + 'onlyCheckblocksAssignedToMe')) == 'true';

    this.showResolved = (await localStorage.getItem(this.tag + 'showResolved')) == 'true';

    this.currentUser = await this.userService.getMe();

    this.filterCheckblocks();
  }

  ngOnChanges() {
    if (this.currentUser) {
      this.filterCheckblocks();
    }
  }

  filterCheckblocks() {
    this.filteredCheckblocks = this.checkBlocks?.filter((cb) => {
      if (!this.showResolved && cb.resolved) {
        return false;
      }

      if (this.showOnlyThoseWaitingForMyComment && cb.messages.length > 0) {
        let lastMessage = cb.messages[cb.messages.length - 1];

        if (lastMessage.userId == this.currentUser._id) {
          return false;
        }
      }

      return true;
    });
  }

  onOnlyCheckblocksWaitingForMyResponseToggle(ev) {
    localStorage.setItem(this.tag + 'onlyCheckblocksAssignedToMe', String(ev.checked));
    this.filterCheckblocks();
  }

  onShowResolvedToggle(ev) {
    localStorage.setItem(this.tag + 'showResolved', String(ev.checked));
    this.filterCheckblocks();
  }
}
