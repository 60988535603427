import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { getAbundance } from '@cpr/shared/abundance';
import { IMeasurement } from '../../measurement/measurement.interface';
import { MeasurementService } from '../../measurement/measurement.service';
import { ISample } from '../../samples/interface/sample.interface';
import { ITaxon } from '../taxon.interface';
import { TaxonService } from '../taxon.service';

type PickMeasurement = Pick<IMeasurement, '_id' | 'analysedCount'>;
type PickTaxon = Pick<ITaxon, 'taxonName' | 'cprTaxonId' | 'countingMethod'> & {
  taxonChildren?: PickTaxon[];
} & {
  measurement?: PickMeasurement;
};

@Component({
  selector: 'app-taxon-hierarchy-item',
  templateUrl: './taxon-hierarchy-item.component.html',
  styleUrls: ['./taxon-hierarchy-item.component.scss'],
})
export class TaxonHierarchyItemComponent implements OnChanges {
  @Input() taxon: PickTaxon;
  @Input() sample: ISample;

  @Input() showCategory: boolean;
  @Input() showDelete: boolean;
  @Input() showID = true;
  @Input() showOther: boolean;
  @Input() showChildren = true;

  @Output() measurementDelete = new EventEmitter<string>();

  category: number;

  fakeOtherTaxon: PickTaxon;

  constructor(
    private readonly measurementService: MeasurementService,
    readonly taxonService: TaxonService, // Used in template
  ) {}

  async ngOnChanges() {
    if (this.taxon.taxonChildren && typeof this.taxon?.measurement?.analysedCount === 'number') {
      this.fakeOtherTaxon = {
        taxonName: this.taxon.taxonName,
        cprTaxonId: this.taxon.cprTaxonId,
        countingMethod: this.taxon.countingMethod,
      };

      const countToNum = (count: PickMeasurement['analysedCount']) => {
        return typeof count === 'number' ? count : 0;
      };

      if (typeof this.taxon.measurement?.analysedCount === 'number') {
        this.fakeOtherTaxon.measurement = {
          analysedCount:
            countToNum(this.taxon.measurement.analysedCount) -
            this.taxon.taxonChildren.reduce(
              (sum, next) => sum + countToNum(next.measurement.analysedCount),
              0,
            ),
        };
      }
    }
    this.category = undefined;

    if (this.showCategory) {
      this.category = getAbundance(
        this.taxon.measurement.analysedCount,
        this.taxon.countingMethod,
        this.sample?.factor,
      );
    }
  }

  deleteMeasurement() {
    this.measurementService.deleteMeasurement(this.taxon.measurement._id, () => {
      this.measurementDelete.emit(this.taxon.measurement._id);
    });
  }
}
