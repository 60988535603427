import { Component, input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { TOW_FAULT_CODES } from './fault-codes.const';

@Component({
  standalone: true,
  imports: [MatButton, MatIcon, MatMenuModule],
  selector: 'app-fault-code-picker',
  template: `
    <button mat-button [matMenuTriggerFor]="menu">
      <span>Add fault</span>
      <mat-icon iconPositionEnd>arrow_drop_down</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      @for (code of codes; track $index) {
        <button mat-menu-item (click)="concatCode(code[1])">{{ code[0] }}: {{ code[1] }}</button>
      }
    </mat-menu>
  `,
})
export class FaultCodePickerComponent {
  readonly formControl = input<FormControl<string>>();

  readonly codes = TOW_FAULT_CODES;

  concatCode(code: string) {
    const oldValue = this.formControl().value.trim();
    this.formControl().setValue(oldValue ? code + '; ' + oldValue : code);
  }
}
