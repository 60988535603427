export const EYECOUNT = {
  id: 1,
  prefix: 'ZE',
  proportionOfSample: 1,
} as const;

export const TRAVERSE = {
  id: 2,
  prefix: 'ZT',
  proportionOfSample: 0.02,
} as const;

export const PHYTO = {
  id: 3,
  prefix: 'P',
  proportionOfSample: 0.0001,
} as const;

export const COUNTING_METHODS = {
  EYECOUNT,
  TRAVERSE,
  PHYTO,
} as const;

export const COUNTING_METHOD_TYPES = Object.keys(COUNTING_METHODS);

export type TCountingMethodType = keyof typeof COUNTING_METHODS;

export type TCountingMethod<T extends TCountingMethodType = TCountingMethodType> =
  (typeof COUNTING_METHODS)[T];
