import { Component, computed, effect, inject, OnDestroy } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { ActivatedRoute } from '@angular/router';
import { BreadcrumbsService } from '@suvo-bi-core';
import { ITowRouteData } from '../../interface/tow-route-data.interface';

@Component({
  selector: 'app-tow-log-screen',
  template: `
    <app-header>
      <a mat-flat-button routerLink="print">Print</a>
    </app-header>

    <div
      style="display: flex; flex-flow: column; gap: var(--global-padding); margin: var(--global-padding)"
    >
      @if (towLog(); as towLog) {
        <app-tow-log-map-tile height="300px" [interactive]="true" />
        <app-tow-log-tile [tow]="tow()" [towLog]="towLog" mode="full" />
      } @else {
        <mat-spinner />
      }
    </div>
  `,
})
export class TowLogScreenComponent implements OnDestroy {
  private readonly breadcrumb = inject(BreadcrumbsService);

  private readonly routeData = toSignal<ITowRouteData>(inject(ActivatedRoute).data);
  readonly tow = computed(() => this.routeData().data);
  readonly towLog = computed(() => this.routeData().towLog());

  private readonly updateBreadcrumb = effect(() => {
    const tow = this.tow();
    const towIdText = tow.metadata?.oldTowBaseId ?? tow._id;
    this.breadcrumb.setDynamicNameSwap('tow-id', towIdText);
  });

  ngOnDestroy() {
    this.updateBreadcrumb.destroy();
  }
}
