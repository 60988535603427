import { Component, ViewChild } from '@angular/core';
import { MatMenuModule, MatMenuTrigger } from '@angular/material/menu';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { ICellEditorParams } from 'ag-grid-community';
import { SamplesModule } from '../../../samples/samples.module';

@Component({
  standalone: true,
  imports: [MatMenuModule, SamplesModule],
  template: `
    <div [matMenuTriggerFor]="menu" (menuClosed)="params.api.stopEditing()"></div>
    <mat-menu #menu="matMenu">
      @for (option of options; track $index) {
        <button mat-menu-item (click)="select(option)">
          <app-pci-colour [value]="option" showLabel="label" />
        </button>
      }
    </mat-menu>
  `,
})
export class PciColourEditorComponent implements ICellEditorAngularComp {
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

  params: ICellEditorParams;
  value: number;

  readonly options = [null, 0, 1, 2, 6.5];

  agInit(params: ICellEditorParams) {
    this.value = params.value;
    this.params = params;
    setTimeout(() => this.trigger.openMenu());
  }

  getValue() {
    return this.value;
  }

  select(value: number) {
    this.value = value;
    this.params.api.stopEditing();
  }
}
