import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { ITableDataSource } from '@suvo-bi-lib';
import { environment } from 'apps/mba-cpr-survey-portal/src/environments/environment';
import { lastValueFrom } from 'rxjs';
import { TableApiService } from 'submodules/frontend-framework/SUVO-BI-CLIENT-LIB/projects/suvo-bi-client-lib/src/lib/features/tables/services/table-api.service';
import { ITableOptions } from 'submodules/frontend-framework/SUVO-BI-CLIENT-LIB/projects/suvo-bi-client-lib/src/lib/shared/interfaces/table/table-options.interface';
import { ConfirmDialogComponent } from '../../../../shared/components/confirm-dialog/confirm-dialog.component';
import { ICheckBlock } from '../../check-block/interface/check-block.interface';
import { IMeasurement } from '../../measurement/measurement.interface';
import { IAnalysisSample } from '../../samples/interface/analysis-sample.interface';
import { ITaxon } from '../../taxon/taxon.interface';
import { IRecordingEvent, TRecordingEventMetadata } from '../interface/recording-event.interface';

@Injectable({ providedIn: 'root' })
export class TowsService extends TableApiService<IRecordingEvent<'tow'>> {
  constructor(
    http: HttpClient,
    private readonly dialog: MatDialog,
  ) {
    super(http, `${environment.cprServiceApi}recording-event/`);
  }

  toDate(tow: IRecordingEvent<'tow'>): Date | void {
    if (!tow.metadata?.month && !tow.metadata?.year) {
      return;
    }
    const date = new Date();
    if (tow.metadata?.year) {
      date.setFullYear(tow.metadata?.year);
    }
    if (tow.metadata?.month) {
      date.setMonth(tow.metadata?.month - 1);
    }
    return date;
  }

  getCheckBlocks(id: string) {
    return this.get<ICheckBlock[]>(id + '/checkblocks');
  }

  getMeasurements(id: string) {
    return this.get<Array<IMeasurement & { taxon: ITaxon }>>(`${id}/measurements`);
  }

  replaceMetadata(id: string, metadata: TRecordingEventMetadata) {
    return lastValueFrom(
      this.httpClient.put(`${environment.cprServiceApi}recording-event/${id}/metadata`, metadata),
    );
  }

  getPaginated(
    suffix: string | string[] = '',
    options: ITableOptions,
    populateTowLogEvents = false,
  ) {
    const params = this.createTableParams(options);
    params.type = 'tow';
    if (populateTowLogEvents) {
      params.towLogEvents = 'populate';
    }
    return this.get<ITableDataSource<IRecordingEvent<'tow'>>>(suffix, params);
  }

  async export(options: ITableOptions, queryParams) {
    const params = this.createTableParams(options);
    params.type = 'tow';
    Object.assign(params, queryParams);

    return this.httpClient
      .get(this.getRequestUrl('export'), { params, responseType: 'text' })
      .toPromise();
  }

  getAnalysis(id: string) {
    return this.get<IAnalysisSample[]>(`${id}/analysis`);
  }

  delete(id: string, onSuccess: () => void) {
    this.dialog.open(ConfirmDialogComponent, {
      data: {
        onConfirm: async () => {
          await this.deleteOne('', id);
          onSuccess?.();
        },
        title: 'Delete Tow',
        body: 'Are you sure you want to delete this tow?',
      },
    });
  }
}
