import { AsyncPipe } from '@angular/common';
import { Component, effect, inject, input, OnDestroy } from '@angular/core';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatCard, MatCardContent, MatCardHeader, MatCardTitle } from '@angular/material/card';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatOption, MatSelect } from '@angular/material/select';
import { MicroscopeService } from '../../../microscopes/service/microscope.service';
import { PermissionsService } from '../../../permissions/permissions.service';
import { ISample } from '../../interface/sample.interface';
import { SamplesService } from '../../service/samples.service';

@Component({
  standalone: true,
  // prettier-ignore
  imports: [
    AsyncPipe,
    ReactiveFormsModule,
    MatButton, MatCard, MatCardContent, MatCardHeader, MatCardTitle, MatFormField, MatLabel, MatInput, MatSelect, MatOption,
    CdkTextareaAutosize,
  ],
  selector: 'app-sample-details-form',
  template: `
    <form [formGroup]="form" (submit)="save()">
      <mat-card class="sample-details">
        <mat-card-header style="display: flex; align-items: center; gap: 16px;">
          <div style="flex-grow: 1">
            <mat-card-title>Sample details</mat-card-title>
          </div>
          @if (hasPermission | async) {
            <button
              mat-stroked-button
              (click)="revert()"
              [disabled]="form.disabled || form.pristine"
            >
              Revert
            </button>
            <button type="submit" mat-flat-button [disabled]="form.disabled || form.pristine">
              Save
            </button>
          }
        </mat-card-header>
        <mat-card-content>
          <mat-form-field style="width: 100%">
            <mat-label>Microscope</mat-label>
            <mat-select formControlName="microscopeId">
              @for (microscope of microscopes(); track $index) {
                <mat-option [value]="microscope._id">
                  <b>{{ microscope.oldMicroscopeId }}</b>
                  {{ microscope.label }}
                </mat-option>
              }
            </mat-select>
          </mat-form-field>

          <mat-form-field style="width: 100%">
            <mat-label>Comments</mat-label>
            <textarea matInput cdkTextareaAutosize formControlName="comment"></textarea>
          </mat-form-field>
        </mat-card-content>
      </mat-card>
    </form>
  `,
})
export class SampleDetailsFormComponent implements OnDestroy {
  private readonly sampleService = inject(SamplesService);
  private readonly permissions = inject(PermissionsService);

  readonly hasPermission = this.permissions.hasPermission('sample', 'analyse');

  readonly microscopes = inject(MicroscopeService).all.value;

  readonly form = new FormGroup({
    microscopeId: new FormControl<string>(null),
    comment: new FormControl<string>(null),
  });

  constructor() {
    this.form.disable();
    this.hasPermission.then((hasPermission) => {
      if (hasPermission) {
        this.form.enable();
      }
    });
  }

  readonly sample = input.required<ISample>();

  private readonly onSampleChange = effect(() => this.revert(), { allowSignalWrites: true });

  ngOnDestroy() {
    this.onSampleChange.destroy();
  }

  revert() {
    this.form.reset();
    this.form.patchValue({
      comment: this.sample()?.comment,
      microscopeId: this.sample()?.microscopeId,
    });
  }

  async save() {
    try {
      this.form.disable();
      const value = this.form.getRawValue();
      await this.sampleService.updateSampleDetails(this.sample()._id, value);
      this.sample().comment = value.comment;
      this.sample().microscopeId = value.microscopeId;
      this.form.markAsPristine();
    } finally {
      this.form.enable();
    }
  }
}
