import { EYECOUNT, PHYTO, TCountingMethod, TRAVERSE } from '../counting-method';

export const ABUNDANCE_CATEGORIES: [
  id: string,
  category: number,
  countingMethod: TCountingMethod,
  min: number,
  max: number,
  mean: number,
  meanPerSample: number,
  meanPerSampleMatlab: number,
][] = [
  ['P1', 1, PHYTO, 1, 2, 1.5, 15000, 15000],
  ['P2', 2, PHYTO, 3, 4, 3.5, 35000, 35000],
  ['P3', 3, PHYTO, 6, 7, 6.5, 65000, 65000],
  ['P4', 4, PHYTO, 9, 10, 9.5, 95000, 95000],
  ['P5', 5, PHYTO, 12, 14, 13, 130000, 130000],
  ['P6', 6, PHYTO, 16, 18, 17, 170000, 170000],
  ['P7', 7, PHYTO, 21, 24, 22.5, 225000, 225000],
  ['P8', 8, PHYTO, 28, 32, 30, 300000, 300000],
  ['P9', 9, PHYTO, 38, 46, 42, 420000, 420000],
  ['P10', 10, PHYTO, 60, 90, 75, 750000, 750000],
  ['P43', 43, PHYTO, 0, 0, 0, 0, -9999],
  ['ZE1', 1, EYECOUNT, 1, 1, 1, 1, 1],
  ['ZE2', 2, EYECOUNT, 2, 2, 2, 2, 2],
  ['ZE3', 3, EYECOUNT, 3, 3, 3, 3, 3],
  ['ZE4', 4, EYECOUNT, 4, 11, 6, 6, 6],
  ['ZE5', 5, EYECOUNT, 12, 25, 17, 17, 17],
  ['ZE6', 6, EYECOUNT, 26, 50, 35, 35, 35],
  ['ZE7', 7, EYECOUNT, 51, 125, 75, 75, 75],
  ['ZE8', 8, EYECOUNT, 126, 250, 160, 160, 160],
  ['ZE9', 9, EYECOUNT, 251, 500, 310, 310, 310],
  ['ZE10', 10, EYECOUNT, 501, 1000, 640, 640, 640],
  ['ZE11', 11, EYECOUNT, 1001, 2000, 1300, 1300, 1300],
  ['ZE12', 12, EYECOUNT, 2001, 4000, 2690, 2690, 2690],
  ['ZE13', 13, EYECOUNT, 4001, 8000, 5012, 5012, 5012],
  ['ZE14', 14, EYECOUNT, 8001, 16000, 12589, 12589, 12589],
  ['ZE43', 43, EYECOUNT, 0, 0, 0, 0, -9999],
  ['ZT1', 1, TRAVERSE, 1, 1, 1, 50, 50],
  ['ZT2', 2, TRAVERSE, 2, 2, 2, 100, 100],
  ['ZT3', 3, TRAVERSE, 3, 3, 3, 150, 150],
  ['ZT4', 4, TRAVERSE, 4, 11, 6, 300, 300],
  ['ZT5', 5, TRAVERSE, 12, 25, 17, 850, 850],
  ['ZT6', 6, TRAVERSE, 26, 50, 35, 1750, 1750],
  ['ZT7', 7, TRAVERSE, 51, 125, 75, 3750, 3750],
  ['ZT8', 8, TRAVERSE, 126, 250, 160, 8000, 8000],
  ['ZT9', 9, TRAVERSE, 251, 500, 310, 15500, 15500],
  ['ZT10', 10, TRAVERSE, 501, 1000, 640, 32000, 32000],
  ['ZT11', 11, TRAVERSE, 1001, 2000, 1300, 65000, 65000],
  ['ZT12', 12, TRAVERSE, 2001, 4000, 2690, 134500, 134500],
  ['ZT13', 13, TRAVERSE, 4001, 8000, 5012, 205600, 205600],
  ['ZT14', 14, TRAVERSE, 8001, 16000, 12589, 629450, 629450],
  ['ZT43', 43, TRAVERSE, 0, 0, 0, 0, -9999],
];

export function getAbundanceCategory(
  count: number,
  countingMethod: typeof EYECOUNT | typeof TRAVERSE,
) {
  const category = ABUNDANCE_CATEGORIES.filter(
    ([, cat, method]) => method === countingMethod && cat != 43,
  ).find(([, , , min, max]) => count >= min && count <= max);

  if (!category) {
    throw new RangeError('Cannot get abundance category - count not in any range');
  }

  return category;
}
