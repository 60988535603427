// prettier-ignore
import { Component, Input, OnDestroy, OnInit, computed, effect, inject, signal } from '@angular/core';
import { Filters, FiltersChannel, ITableDataSource } from '@suvo-bi-lib';
import { latLng, Layer, MapOptions } from 'leaflet';
import { Subject, takeUntil } from 'rxjs';
import { ITableSortDirection } from 'submodules/frontend-framework/SUVO-BI-CLIENT-LIB/projects/suvo-bi-client-lib/src/lib/shared/interfaces/table/table-sort.interface';
import { grid } from '../../../maps/grid';
import { BASE_LAYERS } from '../../../maps/map.constants';
import { MapService } from '../../../maps/map.service';
import { IRecordingEvent } from '../../interface/recording-event.interface';
import { TowsService } from '../../service/tows.service';

@Component({
  selector: 'app-tows-map',
  template: `
    <p>
      Most recent {{ towsResultReturnedCount() | async }} tows of
      {{ towsResultTotalCount() | async }} are shown.
    </p>

    <mat-checkbox
      [disabled]="(towsResultReturnedWithTowLogCount() | async) > alwaysShowTooltipsCutoff"
      [checked]="alwaysShowTooltips()"
      (change)="alwaysShowTooltips.set($event.checked)"
    >
      <mat-label>
        Always show tow ID tooltips?

        @if ((towsResultReturnedWithTowLogCount() | async) > alwaysShowTooltipsCutoff) {
          (disabled due to too many results)
        }
      </mat-label>
    </mat-checkbox>

    <div
      style="height: calc(100vh - 310px)"
      leaflet
      [leafletOptions]="mapOptions"
      [leafletBaseLayers]="baseLayers"
      [leafletLayers]="loading ? [gridLayer] : (layers() | async)"
    >
      @if (loading) {
        <div class="spinner-container">
          <mat-spinner />
        </div>
      }
    </div>

    @if ((towsResultReturnedWithTowLogCount() | async) < (towsResultReturnedCount() | async)) {
      <br />

      <mat-card>
        <mat-card-header>
          <mat-card-title>Note</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <ul>
            <li>
              Some or all of the filtered tows have no tow log and thus will not appear on the map.
            </li>
            <li>
              Tow logs that cross the antimeridian have been temporarily hidden due to an issue with
              Leaflet.
            </li>
          </ul>
        </mat-card-content>
      </mat-card>
    }
  `,
  styles: `
    ::ng-deep .leaflet-grid-label .lng {
      margin-left: 8px;
      -webkit-transform: rotate(90deg);
      transform: rotate(90deg);
    }

    ::ng-deep .leaflet-grid-label .lat,
    ::ng-deep .leaflet-grid-label .lng {
      color: #fff;
      text-shadow:
        -2px 0 #000,
        0 2px #000,
        2px 0 #000,
        0 -2px #000;
    }

    .spinner-container {
      position: absolute;
      z-index: 401;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #fff5;
    }
  `,
})
export class TowsMapComponent implements OnInit, OnDestroy {
  private readonly unsubscribe$ = new Subject<void>();

  @Input() filtersChannel?: FiltersChannel;
  @Input() height: string;

  // Constant

  readonly mapOptions: MapOptions = {
    center: latLng(50.37, -4.14),
    zoom: 3,
  };
  readonly baseLayers = BASE_LAYERS;
  readonly gridLayer = grid();
  readonly alwaysShowTooltipsCutoff = 10;

  // DI

  private readonly mapService = inject(MapService);
  private readonly towsService = inject(TowsService);

  // Effect

  loading = true;
  private readonly effect = effect(() => {
    this.alwaysShowTooltips();
    const layers = this.layers();
    this.loading = true;
    // Because Leaflet doesn't like reactivity
    // We'll use @if to recreate the map when the above signal values change, but not before the next tick
    setTimeout(() => {
      layers.then(() => (this.loading = false));
    });
  });

  // Reactivity

  readonly alwaysShowTooltips = signal(false);

  private readonly filters = signal<Filters>(null);

  private readonly towsResult = computed<Promise<ITableDataSource<IRecordingEvent<'tow'>>>>(() => {
    const filters = this.filters();

    if (!filters) {
      return Promise.resolve({ count: 0, data: [] });
    }

    return this.towsService.getPaginated(
      '',
      { filters, sort: { direction: ITableSortDirection.Descending, active: 'updatedAt' } },
      true,
    );
  });

  readonly towsResultTotalCount = computed(() => this.towsResult().then((res) => res.count));
  readonly towsResultReturnedCount = computed(() =>
    this.towsResult().then((res) => res.data.length),
  );
  readonly towsResultReturnedWithTowLogCount = computed(() =>
    this.towsResult().then((res) =>
      res.data.reduce((sum, next) => (next.events?.length ? ++sum : sum), 0),
    ),
  );

  readonly layers = computed<Promise<Layer[]>>(() => {
    const permanent = this.alwaysShowTooltips();
    const tows = this.towsResult();
    const returnedWithTowLogCount = this.towsResultReturnedWithTowLogCount();

    return (async () => {
      if ((await returnedWithTowLogCount) > this.alwaysShowTooltipsCutoff) {
        this.alwaysShowTooltips.set(false);
      }

      return (await tows).data.reduce(
        (layers, tow) => {
          if (tow.events.length) {
            layers.push(
              this.mapService
                .getTowLogPlot(tow.events)
                ?.eachLayer((layer) => layer.bindTooltip(tow.metadata.oldTowBaseId, { permanent })),
            );
          }
          return layers;
        },
        [this.gridLayer],
      );
    })();
  });

  ngOnInit() {
    this.filtersChannel.filters
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((filters) => this.filters.set(filters));
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.effect.destroy();
  }
}
