import { FormControl, ValidatorFn } from '@angular/forms';

export const measurementCountValidator: ValidatorFn = (ctrl) => {
  const asNum = parseFloat(ctrl.value);
  return (!isNaN(asNum) && Number.isInteger(asNum) && asNum >= 0) || ctrl.value === '+'
    ? null
    : { invalidValue: true };
};

export const measurementCountFormControl = (value: string = null) =>
  new FormControl<string>(value, measurementCountValidator);
