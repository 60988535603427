import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { IMeasurement } from '../../measurement/measurement.interface';
import { ISample } from '../../samples/interface/sample.interface';
import { ITaxon } from '../taxon.interface';
import { TaxonService } from '../taxon.service';

@Component({
  selector: 'app-taxon-hierarchy',
  template: `
    @for (taxon of hierarchy; track taxon._id) {
      <app-taxon-hierarchy-item
        [sample]="sample"
        [taxon]="taxon"
        [showCategory]="showCategories"
        [showDelete]="showDelete"
        (measurementDelete)="measurementDelete.emit($event)"
      />
    }
  `,
})
export class TaxonHierarchyComponent implements OnChanges {
  @Input() sample: ISample;
  @Input() measurements: IMeasurement[] = [];
  @Input() countingMethod: ITaxon['countingMethod'];
  @Input() showCategories: boolean;
  @Input() showDelete: boolean;
  @Output() measurementDelete = new EventEmitter<string>();

  hierarchy: (ITaxon & { measurement?: IMeasurement })[];

  constructor(private readonly taxonService: TaxonService) {}

  async ngOnChanges() {
    this.hierarchy = await this.taxonService.getHierarchy({
      measurements: this.measurements,
      countingMethod: this.countingMethod,
    });
  }
}
