export const TOW_FAULT_CODES: [code: number, desc: string][] = [
  [1, 'Instrumentation package damaged'],
  [2, 'Instrumentation package failed'],
  [3, 'Instrumentation package lost'],
  [4, 'Damage to fusee'],
  [5, 'Silk loaded incorrectly'],
  [6, 'Fusee wire displaced / broken'],
  [7, 'Rollers damaged'],
  [8, 'Tank fingers bent'],
  [9, 'Cover silk torn'],
  [10, 'Filter silk torn'],
  [11, 'Cover silk distorted'],
  [12, 'Filter silk distorted'],
  [13, 'Spools damaged'],
  [14, 'Tie rods broken'],
  [15, 'Tank bushes broken'],
  [16, 'Internal mech damaged other'],
  [17, 'Blister damaged'],
  [18, 'Coupling broken'],
  [19, 'Bod frame structural failure'],
  [20, 'Drive train failure including ear box'],
  [21, 'Propeller Lost'],
  [22, 'Propeller damaged'],
  [23, 'Intermittent silk drive inc Gear mesh'],
  [24, 'Nose cone damaged'],
  [25, 'Tail fin damaged'],
  [26, 'Tail fin lost'],
  [27, 'Fender damaged'],
  [28, 'Fender lost'],
  [29, 'Tow point / shock absorber damaged'],
  [30, 'Tow wire damaged'],
  [31, 'Shackles damaged'],
  [32, 'Ballast lead loose'],
  [33, 'Slide stops'],
  [34, 'Damaged in transit'],
  [35, 'Damaged on deployment'],
  [36, 'Damaged on retrieval'],
  [37, 'CPR lost'],
  [38, 'CPR internal lost'],
  [39, 'Towed on wrong route'],
  [40, 'Towed twice'],
  [41, 'Overrun'],
  [42, 'Part tow'],
  [43, 'Bad weather, tow aborted'],
  [45, 'Bird'],
  [46, 'Sand'],
  [47, 'Fish'],
  [48, 'Fishing line'],
  [49, 'Fishing net'],
  [50, 'Rope'],
  [51, 'Sea weed'],
  [52, 'Plastic'],
  [53, 'Other'],
  [54, 'Unforeseen circumstances'],
];
