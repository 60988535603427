import { CommonModule } from '@angular/common';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { ICellEditorParams } from 'ag-grid-community';

@Component({
  standalone: true,
  templateUrl: './custom-date.component.html',
  styleUrls: ['./custom-date.component.scss'],
  imports: [CommonModule, FormsModule],
})
export class CustomDateComponent implements ICellEditorAngularComp {
  @ViewChild('dayInput') dayInput: ElementRef<HTMLInputElement>;

  private params: ICellEditorParams;

  day: number;
  month: number;
  year: number;
  hour: number;
  min: number;

  agInit(params: ICellEditorParams) {
    this.params = params;

    let date: Date;

    if (typeof this.params.value === 'string') {
      date = new Date(this.params.value);
    }

    if (date) {
      this.year = date.getUTCFullYear();
      this.month = date.getUTCMonth() + 1;
      this.day = date.getUTCDate();
      this.hour = date.getUTCHours();
      this.min = date.getUTCMinutes();
    }

    setTimeout(() => {
      this.dayInput.nativeElement.focus();
    });
  }

  getValue(): string {
    for (const field of [this.day, this.month, this.year]) {
      if (!Number.isInteger(field)) {
        return;
      }
    }
    if (this.year.toString().length != 4) {
      return;
    }
    const isoString = `${this.year}-${this.month.toString().padStart(2, '0')}-${this.day.toString().padStart(2, '0')}T${this.hour?.toString().padStart(2, '0') ?? '00'}:${this.min?.toString().padStart(2, '0') ?? '00'}:00.000Z`;
    if (!Date.parse(isoString)) {
      return;
    }
    return isoString;
  }

  /** Stop propagation to AG Grid and stay within the HTML form. */
  onKeyDown(event: KeyboardEvent, position: 'start' | 'end' = null) {
    if (event.key === 'Tab') {
      if ((position === 'start' && event.shiftKey) || (position === 'end' && !event.shiftKey)) {
        return;
      }
      event.stopPropagation();
    }
  }
}
