export const PHYTO_FACTOR_RANGES: [factor: number, factorMin: number, factorMax: number][] = [
  [0.75, 0, 0.75],
  [0.8, 0.75, 0.8],
  [0.9, 0.8, 0.9],
  [1, 0.9, 1],
  [1.1, 1, 1.1],
  [1.2, 1.1, 1.2],
  [1.25, 1.2, 99],
];

export function getPhytoFactorRange(factor: number) {
  const range = PHYTO_FACTOR_RANGES.find(([, min, max]) => factor > min && factor <= max);

  if (!range) {
    throw new RangeError('Value not in any phyto factor range');
  }

  return range;
}
