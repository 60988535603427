<div
  class="item"
  [class.bold]="showChildren && taxon.taxonChildren?.length"
  [class.no-measurement]="!taxon.measurement"
>
  <div style="flex-grow: 1">
    <span>{{ taxon.taxonName }}</span>
    @if (showOther) {
      <span style="color: #88f">&nbsp;(extra)</span>
    }
    <span style="opacity: 50%" *ngIf="showID"> (ID: {{ taxon.cprTaxonId }})</span>
  </div>
  @if (taxon.measurement) {
    @if (showCategory && category) {
      <span style="opacity: 50%">(C{{ category }})</span>
    }
    <span style="min-width: 40px; text-align: right">{{ taxon.measurement?.analysedCount }}</span>
    <ng-content />
    @if (showDelete) {
      <button mat-icon-button [disabled]="sample?.finalisedAt" (click)="deleteMeasurement()">
        <mat-icon>delete</mat-icon>
      </button>
    }
  }
</div>

@if (showChildren && taxon.taxonChildren?.length) {
  <div class="children">
    @if (taxon?.countingMethod != 'PHYTO' && taxon?.measurement?.analysedCount > 0) {
      <app-taxon-hierarchy-item
        [sample]="sample"
        [taxon]="fakeOtherTaxon"
        [showCategory]="showCategory"
        [showID]="false"
        [showOther]="true"
      />
    }
    @for (child of taxon.taxonChildren; track child._id) {
      <app-taxon-hierarchy-item [sample]="sample" [taxon]="child" [showCategory]="showCategory" />
    }
  </div>
}
