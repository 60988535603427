import { AsyncPipe } from '@angular/common';
import { Component, inject } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { SamplesService } from '../../../samples/service/samples.service';
import { FinalisationReport } from '../finalisation-report/finalisation-report.component';

export interface FinaliseSampleDialogData {
  sampleId: string;
  onConfirm: () => void;
}

@Component({
  standalone: true,
  imports: [AsyncPipe, FinalisationReport, MatButton, MatDialogModule, MatIcon, MatProgressSpinner],
  templateUrl: './finalise-sample-dialog.component.html',
})
export class FinaliseSampleDialogComponent {
  private readonly dialogData = inject<FinaliseSampleDialogData>(MAT_DIALOG_DATA);
  private readonly sampleService = inject(SamplesService);

  readonly checks = this.sampleService
    .check(this.dialogData.sampleId)
    .catch((e) => e.error.message);

  readonly checksValid = this.checks.then((checks) => checks.every(([valid]) => valid !== 'ERR'));

  finalising = false;

  finalise() {
    this.finalising = true;
    this.sampleService
      .finalise(this.dialogData.sampleId)
      .then(() => this.dialogData.onConfirm())
      .finally(() => (this.finalising = false));
  }

  cancel() {
    this.sampleService.unfinalise(this.dialogData.sampleId);
  }
}
