import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { UsersModule } from '../../../users/users.module';

@Component({
  standalone: true,
  imports: [UsersModule],
  template: `
    @if (userId) {
      <app-user-avatar [class.warn]="analystTooCloseToSelf" [userId]="userId" />
    }
  `,
  styles: `
    app-user-avatar.warn {
      color: red !important;
    }
  `,
})
export class AnalystRendererComponent implements ICellRendererAngularComp {
  analystTooCloseToSelf: boolean;
  userId: string;

  agInit(params: ICellRendererParams & { analystTooCloseToSelf: boolean }) {
    this.analystTooCloseToSelf = params.analystTooCloseToSelf;
    this.userId = params.value;
  }

  refresh(params: ICellRendererParams & { analystTooCloseToSelf: boolean }) {
    this.agInit(params);
    return true;
  }
}
