import { Component, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { IError } from '@suvo-bi-core';
import { EditComponent } from 'apps/mba-cpr-survey-portal/src/app/shared/components/edit/edit.component';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { STATUSES, TStatus } from '../../../../../shared/components/status-chip/status.type';
import { IRecordingEvent } from '../../interface/recording-event.interface';
import { ITow } from '../../interface/tow.interface';
import { TowsService } from '../../service/tows.service';
import { FilterConditionType, FilterOperatorType } from '@suvo-bi-lib';

@Component({
  selector: 'app-tow-edit-screen',
  templateUrl: './tow-edit-screen.component.html',
  styles: `
    .input-group {
      display: flex;
      flex-flow: row wrap;
      gap: var(--global-padding);
      justify-content: left;
      margin: var(--global-padding) 0;

      > * {
        flex-grow: 1;
      }
    }

    :host ::ng-deep .mat-form-field-wrapper {
      padding-bottom: 0 !important;
    }
  `,
})
export class TowEditScreenComponent {
  @ViewChild('editComponent') editComponent: EditComponent;
  private readonly unsubscribe$ = new Subject<void>();

  shipFilters = {
    name: 'Ship Filters',
    query: {
      op: FilterOperatorType.And,
      rules: [{ key: 'endDate', con: [{ type: FilterConditionType.NotExists }] }],
    },
  };

  readonly formGroup = new FormGroup({
    oldTowBaseId: new FormControl<string>(''),
    status: new FormControl<TStatus>('created'),
    month: new FormControl<number>(null),
    year: new FormControl<number>(null),
    routeId: new FormControl<string>(''),
    shipId: new FormControl<string>(''),
    cprId: new FormControl<string>(''),
    internalId: new FormControl<string>(''),
    silkReadingStart: new FormControl<number>(null),
    silkReadingEnd: new FormControl<number>(null),
    message: new FormControl<string>(''),
    validity: new FormControl<string>(''),
    instrumentId: new FormControl<string>(''),
    serialNumberPerCalendarMonth: new FormControl<string>(''),
    propSetting: new FormControl<number>(null),
    routeStatus: new FormControl<string>(''),
    factor: new FormControl<string>(''),
    masterName: new FormControl<string>(''),
    formalinreading: new FormControl<number>(null),
    partOne: new FormControl<string>(''),
    partTwo: new FormControl<string>(''),
    fromPortName: new FormControl<string>(''),
    toPortName: new FormControl<string>(''),
    instrumentDataFile: new FormControl<string>(''),
    cut: new FormControl<string>('', (ctl) =>
      ctl.value && Number.isNaN(Date.parse(ctl.value)) ? { invalidDate: true } : null,
    ),
  });

  readonly statusOptions = STATUSES.filter((status) => status !== 'finalised');
  readonly validityOptions = [
    'JAM',
    'LOST',
    'OK',
    // 'OK,IN',
    // 'OK,OUT',
    'QLA',
    // 'QLA,IN',
    // 'QLA,OUT',
    'QUAL',
  ] as const;

  @ViewChild('edit') edit: EditComponent;

  tow: IRecordingEvent<'tow'>;

  serverErrors: Array<IError>;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly towsService: TowsService,
  ) {
    this.route.data
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(({ data }: { data: IRecordingEvent<'tow'> }) => {
        if ((this.tow = data)) {
          this.formGroup.setValue({
            oldTowBaseId: data.metadata.oldTowBaseId,
            status: data.metadata.status ?? 'created',
            routeId: data.metadata.routeId ?? '',
            instrumentId: data.metadata.instrumentId ?? '',
            shipId: data.metadata.shipId ?? '',
            cprId: data.metadata.cprId ?? '',
            internalId: data.metadata.internalId ?? '',
            serialNumberPerCalendarMonth: data.metadata.serialNumberPerCalendarMonth ?? '',
            propSetting: data.metadata.propSetting ?? null,
            routeStatus: data.metadata.routeStatus ?? '',
            message: data.metadata.message ?? '',
            factor: data.metadata.factor ?? '',
            silkReadingStart: data.metadata.silkReadingStart ?? null,
            silkReadingEnd: data.metadata.silkReadingEnd ?? null,
            masterName: data.metadata.masterName ?? '',
            formalinreading: data.metadata.formalinreading ?? null,
            partOne: data.metadata.partOne ?? '',
            partTwo: data.metadata.partTwo ?? '',
            fromPortName: data.metadata.fromPortName ?? '',
            toPortName: data.metadata.toPortName ?? '',
            instrumentDataFile: data.metadata.instrumentDataFile ?? '',
            month: data.metadata.month ?? null,
            year: data.metadata.year ?? null,
            validity: data.metadata.validity ?? '',
            cut: (data.metadata.cut as string)?.slice(0, 10) ?? '',
          });
          this.formGroup.markAsPristine();
        }
      });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  async onSave($event: ITow) {
    for (const property of ['cprId', 'routeId', 'shipId']) {
      if ($event[property] instanceof Array) {
        $event[property] = $event[property][0];
      }
    }

    this.serverErrors = null;

    try {
      if (this.tow?._id) {
        await this.towsService.replaceMetadata(this.tow._id, $event);
        this.editComponent.markAsUpdated();
      } else {
        const { _id } = await this.towsService.postOne('', {
          type: 'tow',
          metadata: $event,
        });
        await this.router.navigate(['..', _id], { relativeTo: this.route });
      }
    } catch (e) {
      this.serverErrors = [e.error];
      this.edit.enable();
    }
  }

  formatDate(date: Date | string): string {
    if (!date) return '';
    date = new Date(date);
    const day = `${date.getDate() < 10 ? '0' : ''}${date.getDate()}`;
    const month = `${date.getMonth() + 1 < 10 ? '0' : ''}${date.getMonth() + 1}`;
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  }
}
