import { EYECOUNT, TCountingMethodType, TRAVERSE } from '../counting-method';
import { getAbundanceCategory } from './abundance-category';
import { FINALISING_FACTORS } from './finalising-factors';
import { getPhytoFactorRange } from './phyto-factor-ranges';

export function getAbundance(
  count: number | '+',
  countingMethod?: TCountingMethodType,
  sampleFactor?: number,
) {
  if (count === '+') {
    return 43;
  }

  switch (countingMethod) {
    case 'EYECOUNT': {
      return getAbundanceCategory(count, EYECOUNT)[1];
    }

    case 'TRAVERSE': {
      if (!sampleFactor) {
        throw new TypeError('Cannot get abundance - missing sample factor');
      }

      return getAbundanceCategory(Math.round(count * sampleFactor), TRAVERSE)[1];
    }

    case 'PHYTO': {
      if (!sampleFactor) {
        throw new TypeError('Cannot get abundance - missing sample factor');
      }

      const factorRange = getPhytoFactorRange(sampleFactor);

      if (!factorRange) {
        throw new RangeError('Cannot get abundance - factor not in any range');
      }

      const [sampleFactorNormalised] = factorRange;

      const finalisingFactor = FINALISING_FACTORS.find(
        ([fields, factor]) => fields === count && factor === sampleFactorNormalised,
      );

      if (!finalisingFactor) {
        throw new RangeError('Cannot get abundance - could not determine finalising factor value');
      }

      return Math.round(
        (sampleFactor <= 1 ? count - finalisingFactor[2] : count + finalisingFactor[2]) / 2,
      );
    }

    default:
      throw new TypeError('Cannot get abundance - missing counting method');
  }
}
