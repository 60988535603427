import { AsyncPipe } from '@angular/common';
import { Component, inject, input, output } from '@angular/core';
import { MatFormField, MatLabel, MatOption, MatSelect } from '@angular/material/select';
import { UsersService } from '../../../users/service/users.service';
import { UsersModule } from '../../../users/users.module';
import { CheckBlockService } from '../../check-block.service';

@Component({
  standalone: true,
  imports: [AsyncPipe, MatFormField, MatLabel, MatOption, MatSelect, UsersModule],
  selector: 'app-check-block-assignee',
  template: `
    <mat-form-field style="width: 100%">
      <mat-label>Assignee</mat-label>
      <mat-select
        required
        placeholder="UNASSIGNED"
        [value]="userId()"
        (valueChange)="onAssign($event)"
      >
        <mat-option>UNASSIGNED</mat-option>
        @for (user of userService.analysts | async; track user._id) {
          <mat-option [value]="user._id">
            @if (user.legacyAnalystId) {
              ({{ user.legacyAnalystId }})
            }
            {{ user.name ?? user.displayName }}
          </mat-option>
        }
      </mat-select>
    </mat-form-field>
  `,
})
export class CheckBlockAsssigneeComponent {
  readonly checkBlockId = input<string>();
  readonly userId = input<string>();

  readonly userIdChange = output<string>();

  readonly checkBlockService = inject(CheckBlockService);
  readonly userService = inject(UsersService);

  async onAssign(event: string) {
    await this.checkBlockService.assign(this.checkBlockId(), event);
    this.userIdChange.emit(event);
  }
}
