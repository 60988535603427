import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { Router } from '@angular/router';
import { PermissionsService } from '../../../../../permissions/permissions.service';
import { SamplesService } from '../../../../../samples/service/samples.service';
import { IRecordingEvent } from '../../../../interface/recording-event.interface';
import { ITowLogEvent } from '../../../../interface/tow-log-event.interface';
import { CuttingPointService } from '../../../../service/cutting-point.service';

@Component({
  selector: 'app-tow-log-tile',
  templateUrl: './tow-log-tile.component.html',
  styleUrls: ['./tow-log-tile.component.scss'],
})
export class TowLogTileComponent implements OnChanges {
  @Input() tow: IRecordingEvent<'tow'>;
  @Input() towLog: ITowLogEvent[];
  @Input() mode: 'simple' | 'full' = 'full';

  @Output() towLogPushed = new EventEmitter<void>();

  edit = false;
  cutsGenerated = true;
  generateBtn: { disabled: boolean; reason?: string } = {
    disabled: true,
  };

  constructor(
    private readonly router: Router,
    private readonly cuttingPointService: CuttingPointService,
    private readonly permissionService: PermissionsService,
    private readonly sampleService: SamplesService,
  ) {}

  async ngOnChanges() {
    let options = { simpleFilters: { towId: `ObjectId:${this.tow._id}` } };
    const [cuts, samples] = await Promise.all([
      this.cuttingPointService.getPaginated('', options),
      this.sampleService.getPaginated('', options),
    ]);
    this.cutsGenerated = cuts.count > 0 || samples.count > 0;
    this.checkMayGenerate();
  }

  onTowLogPush() {
    this.ngOnChanges();
    this.towLogPushed.emit();
    this.checkMayGenerate();
  }

  onEditModeChange(event: boolean) {
    this.edit = event;
    this.checkMayGenerate();
  }

  checkMayGenerate() {
    if (!this.permissionService.hasPermission('sample', 'generate')) {
      this.generateBtn = {
        disabled: true,
        reason: 'You lack the required permission',
      };
    } else if (this.edit) {
      this.generateBtn = {
        disabled: true,
        reason: 'Please save changes first',
      };
    } else if (!this.towLog?.length) {
      this.generateBtn = {
        disabled: true,
        reason: 'Please fill the tow log',
      };
    } else if (this.cutsGenerated) {
      this.generateBtn = {
        disabled: true,
        reason: 'Samples have already been generated',
      };
    } else {
      this.generateBtn = { disabled: false };
    }
  }

  onGenerate() {
    return this.sampleService.generateWithDialog(this.tow._id, () => {
      this.cutsGenerated = true;
      this.router.navigate(['/tows', this.tow._id, 'samples', 'allocate']);
    });
  }
}
